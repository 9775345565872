var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{ref:"multipleTable",staticClass:"table-box",attrs:{"data":_vm.tableData,"header-cell-style":{
            background: '#FAFAFA',
            color: '#333333',
            'font-size': '14px',
            'font-weight': '500',
        }}},[_c('el-table-column',{attrs:{"prop":"","width":"10"}}),_c('el-table-column',{attrs:{"label":"产品类别","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                        scope.row.productAttributeRelationBOList &&
                        scope.row.productAttributeRelationBOList.length > 0
                    )?_c('span',[_vm._v(_vm._s(scope.row.productAttributeRelationBOList[0].productName))]):_c('span',[_vm._v("- -")])]}}])}),_c('el-table-column',{attrs:{"label":"版本","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm.returnText(scope.row, 'version'))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"购买类型","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm.returnText(scope.row, 'productType')))])]}}])}),_c('el-table-column',{attrs:{"label":"用户数","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                        scope.row.productCategory != 5 &&
                        scope.row.productCategory != 6 &&
                        scope.row.productCategory != 8 &&
                        scope.row.productCategory != 9 &&
                        scope.row.productCategory != 12 &&
                        scope.row.productCategory != 3
                    )?_c('span',[_vm._v(_vm._s(scope.row.number)+" 用户")]):_c('span',[_vm._v("- -")])]}}])}),_c('el-table-column',{attrs:{"label":"年限","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                        scope.row.productCategory != 6 &&
                        scope.row.productCategory != 9 &&
                        scope.row.productCategory != 11 &&
                        scope.row.productType != 3
                    )?_c('span',[_vm._v(_vm._s(scope.row.term)+" 年")]):_c('span',[_vm._v("- -")])]}}])}),_c('el-table-column',{attrs:{"label":"金额","prop":"receivableAmount","show-overflow-tooltip":""}}),_c('div',{staticClass:"empty",attrs:{"slot":"empty"},slot:"empty"},[_c('img',{attrs:{"src":require("@/assets/zanwushuju.png")}}),_c('span',{staticStyle:{"display":"block"}},[_vm._v("暂无数据～")])])],1),_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"total"},[_c('p',[_vm._v("合计")]),_c('p',[_vm._v(" "+_vm._s(_vm.total)+" "),_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v("元")])])]),_c('div',{staticClass:"detailed"},[_c('p',[_vm._v("明细")]),_c('p',[_vm._v(_vm._s(_vm.data.remark))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }