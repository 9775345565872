import http from '@/utils/http.js'

// 发票列表
export function invoiceList(data) {
    return http({
        url: "/jasoboss/contract/invoice/list",
        method: "post",
        data
    })
}
// 发票添加
export function invoiceAdd(data) {
    return http({
        url: "/jasoboss/contract/invoice/add",
        method: "post",
        data
    })
}
// 发票编辑
export function invoiceUpdate(data) {
    return http({
        url: "/jasoboss/contract/invoice/update",
        method: "post",
        data
    })
}
// 发票单个
export function invoiceGet(data) {
    return http({
        url: "/jasoboss/contract/invoice/get",
        method: "post",
        data
    })
}
// 发票审核
export function invoiceAudit(data) {
    return http({
        url: "/jasoboss/contract/invoice/audit/add",
        method: "post",
        data
    })
}