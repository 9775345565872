<template>
    <div class="plan" v-if="data.length > 0">
        <div class="statistics">
            <p>
                <span>合同金额</span>
                <span>{{ data[0].contractAmount }}元</span>
            </p>
            <p>
                <span>计划回款总金额</span>
                <span>{{ allMoney.toFixed(2) }}元</span>
            </p>
            <p>
                <span>已回款总金额</span>
                <span>{{ allOnMoney.toFixed(2) }}元</span>
            </p>
        </div>
        <div class="content_list" v-for="(item, index) in data">
            <p class="title">
                回款计划{{ index + 1 }}

                <span :class="item.returnStatus == 1 ? 'off' : 'on'">
                    <i
                        :class="
                            item.returnStatus == 1
                                ? 'el-icon-error'
                                : 'el-icon-success'
                        "
                    ></i>
                    {{ item.returnStatus == 1 ? '未到款' : '已到款' }}
                </span>

                <el-button
                    v-if="
                        index > 0 &&
                        item.returnStatus == 1 &&
                        item.billingStatus == 1 &&
                        btnP.delMoney
                    "
                    type="text"
                    style="
                        color: red;
                        float: right;
                        padding: 0;
                        margin-right: 12px;
                    "
                    @click="del(item)"
                >
                    删除
                </el-button>
                <el-button
                    type="text"
                    style="
                        color: #333;
                        float: right;
                        padding: 0;
                        margin-right: 12px;
                    "
                    @click="edit(item)"
                    v-if="item.returnStatus == 1 && btnP.uodateMoney"
                >
                    <i class="el-icon-edit-outline" style="color: #2370eb"></i>
                    编辑
                </el-button>
                <el-button
                    type="text"
                    style="
                        color: #333;
                        float: right;
                        padding: 0;
                        margin-right: 12px;
                    "
                    v-if="
                        item.returnStatus == 1 &&
                        item.adminId == adminId &&
                        btnP.matching
                    "
                    @click="onMatching(item)"
                >
                    <i class="el-icon-edit-outline" style="color: #2370eb"></i>
                    匹配回款
                    <!-- 
                    v-if="btnP.matching" -->
                </el-button>
            </p>
            <div class="list_box">
                <div class="list_form">
                    <el-form
                        label-width="100px"
                        label-position="left"
                        size="small"
                    >
                        <el-form-item label="回款金额" :required="!isUpData">
                            <el-input
                                :disabled="isUpData"
                                v-model="item.returnAmount"
                                placeholder="请输入"
                                style="width: 240px; margin-right: 27px"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            v-if="item.returnStatus == 2"
                            label="回款方式"
                            :required="!isUpData"
                        >
                            <div style="padding-left: 15px">
                                {{
                                    item.paymentWay == 1
                                        ? '扫码支付'
                                        : item.paymentWay == 2
                                        ? '对公转账'
                                        : '企业微信回款'
                                }}
                            </div>
                        </el-form-item>
                        <el-form-item
                            label="回款交易单号"
                            :required="!isUpData"
                            v-if="item.paymentWay == 3"
                        >
                            <div style="padding-left: 15px">
                                {{ item.tradeNo ? item.tradeNo : '- -' }}
                            </div>
                        </el-form-item>
                        <el-form-item
                            label="回款比例"
                            :required="!isUpData"
                            v-if="item.paymentWay != 3"
                        >
                            <div style="padding-left: 15px">
                                {{
                                    (
                                        (item.returnAmount /
                                            item.contractAmount) *
                                        100
                                    ).toFixed(2) + '%'
                                }}
                            </div>
                        </el-form-item>
                        <el-form-item
                            label="回款年月"
                            :required="!isUpData"
                            v-if="item.paymentWay != 3"
                        >
                            <div style="padding-left: 15px">
                                {{ item.returnTime.slice(0, 7) }}
                            </div>
                        </el-form-item>
                        <el-form-item
                            label="回款时间"
                            v-if="item.paymentWay == 3"
                            :required="!isUpData"
                            ><div style="padding-left: 15px">
                                {{ item.createTime ? item.createTime : '- -' }}
                            </div>
                        </el-form-item>
                        <el-form-item
                            label="付款人"
                            :required="!isUpData"
                            v-if="item.paymentWay == 3"
                        >
                            <div style="padding-left: 15px">
                                {{
                                    item.externalUserName
                                        ? item.externalUserName
                                        : '- -'
                                }}
                            </div>
                        </el-form-item>

                        <el-form-item
                            label="付款户名"
                            :required="!isUpData"
                            v-if="item.paymentWay != 3"
                        >
                            <el-input
                                :disabled="isUpData"
                                v-model="item.contractA"
                                placeholder="请输入"
                                style="width: 240px; margin-right: 27px"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="备注"
                            :required="!isUpData"
                            v-if="item.paymentWay != 3"
                        >
                            <span style="padding-left: 15px">
                                {{ item.remark || '- -' }}
                            </span>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="list_img">
                    <el-form
                        label-width="70px"
                        v-if="item.qrcodeUrl && item.returnStatus == 1"
                        label-position="left"
                        size="small"
                    >
                        <el-form-item label="收款码">
                            <img
                                :src="item.qrcodeUrl"
                                alt=""
                                style="width: 150px; height: 150px"
                            />
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div
                class="table_box"
                v-if="
                    item.returnStatus == 1 &&
                    tableData[index] &&
                    tableData[index].length > 0
                "
            >
                <p>待匹配回款信息</p>
                <el-table
                    class="table-box"
                    ref="multipleTable"
                    :data="tableData[index]"
                    height="100%"
                    :header-cell-style="{
                        background: '#FAFAFA',
                        color: '#333333',
                        'font-size': '14px',
                        'font-weight': '500',
                    }"
                >
                    <el-table-column prop="" width="10"> </el-table-column>
                    <el-table-column label="回款金额" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.arriveAmount || '- -' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="到账时间" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.arriveTime || '- -' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="付款户名" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.contractA || '- -' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="入账公司" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.contractB || '- -' }}
                            </div>
                        </template>
                    </el-table-column>

                    <div slot="empty" class="empty">
                        <img src="@/assets/zanwushuju.png" />
                        <span style="display: block">暂无数据～</span>
                    </div>
                </el-table>
            </div>
        </div>
        <!-- 匹配回款（企微收款） -->
        <el-dialog
            :visible.sync="dialogMatching"
            width="760px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    匹配回款
                </div>
            </template>
            <Matching @close="handleClose" ref="matching" />
        </el-dialog>
    </div>
</template>
<script>
import { planArriveList } from '@/api/contr/money.js';
import { arriveList } from '@/api/arrive/arrive.js';
import Matching from './matching.vue';
export default {
    components: {
        Matching,
    },
    data() {
        return {
            data: [],
            matchingData: [],
            isUpData: true,
            tableData: [],
            allMoney: 0,
            allOnMoney: 0,
            btnP: {},
            dialogMatching: false,
            adminId: sessionStorage.getItem('adminId'),
        };
    },
    methods: {
        getData(data, allMoney, oneContract, btnP) {
            this.btnP = btnP;
            this.allOnMoney = 0;
            this.data = data;
            data.forEach((item, index) => {
                if (item.returnStatus == 2) {
                    this.allOnMoney += item.returnAmount;
                }

                this.planArriveList(item.contractA, item.contractB, index);
            });

            this.allMoney = allMoney;
        },

        planArriveList(contractA, contractB, index) {
            let data = {
                param: {
                    contractA,
                    contractB,
                },
                pageNum: 0,
                pageSize: 0,
            };
            planArriveList(data).then((res) => {
                if (res.code == 200) {
                    this.tableData.push(res.data.list);
                    // // console.log(this.data[index].tableData);
                }
            });
        },
        edit(data) {
            this.$emit('edit', data);
        },
        del(data) {
            this.$emit('del', data);
        },
        onMatching(data) {
            this.dialogMatching = true;
            setTimeout(() => {
                this.$refs.matching.$data.tradeNoVal = '';
                this.$refs.matching.getData(data);
            });
        },
        handleClose() {
            this.dialogMatching = false;
            this.$emit('getMoney');
        },
    },
};
</script>
<style lang="scss" scoped>
.plan {
    .statistics {
        margin: 11px 20px 0 20px;
        background: #f0f4ff;
        border-radius: 7px;
        display: flex;
        font-size: 13px;
        padding: 14px 20px;
        p {
            margin-right: 40px;
            span:nth-child(1) {
                color: #333333;
                margin-right: 20px;
            }
            span:nth-child(2) {
                color: #2370eb;
            }
        }
    }
    .content_list {
        padding-left: 40px;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        .title {
            font-size: 13px;
            padding: 16px 0;
            font-weight: 600;
            color: #333333;
            > span {
                display: inline-block;
                width: 88px;
                height: 24px;
                border-radius: 12px;
                line-height: 24px;
                margin-left: 15px;
                text-align: center;
                font-size: 12px;
                font-weight: normal !important;
            }
            .on {
                background-color: #e0eaff;
                i {
                    color: #82a7fc;
                }
            }
            .off {
                background-color: #ece2fe;
                i {
                    color: #7f3af5;
                }
            }
        }
        .list_box {
            display: flex;
        }
        .list_img {
            flex: 1;
        }
    }
    .content_list:last-child {
        border: none;
    }
}
.el-input--small {
    font-size: 14px !important;
}
.table_box {
    p {
        font-size: 14px;
        color: #333;
        font-weight: 600;
        margin-bottom: 10px;
    }
}
.el-form-item {
    margin-bottom: 8px !important;
}
</style>
<style>
.plan .content_list .el-input.is-disabled .el-input__inner {
    background: #fff !important;
}
.plan .content_list .el-input.is-disabled .el-input__inner::placeholder {
    display: none !important;
    color: #fff !important;
}
.plan .content_list .el-form-item__label {
    color: #666666 !important;
}
</style>
