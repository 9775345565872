<template>
    <div class="basic">
        <el-form
            label-width="75px"
            label-position="left"
            size="small"
            ref="form"
        >
            <div class="form_box">
                <div class="line">
                    <el-form-item label="合同编号" :required="!isUpData">
                        <el-input
                            :disabled="true"
                            v-model="form.contractNo"
                            placeholder="请输入"
                            style="width: 240px; margin-right: 27px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="客户名称" :required="!isUpData">
                        <span style="margin-left: 15px" v-show="isUpData">{{
                            data.companyName
                        }}</span>
                        <el-select
                            v-show="!isUpData"
                            filterable
                            remote
                            :remote-method="remoteMethod"
                            v-model="customerId"
                            style="width: 240px"
                            clearable
                            placeholder="请选择"
                            @change="getRepresentA()"
                        >
                            <el-option
                                v-for="item in customerData"
                                :key="item.id"
                                :label="item.companyName"
                                :value="item.id"
                            >
                                <span style="float: left">{{
                                    item.companyName
                                }}</span>
                                <span
                                    style="
                                        float: right;
                                        color: #8492a6;
                                        font-size: 13px;
                                    "
                                    >{{
                                        item.type == 1
                                            ? '线索'
                                            : item.type == 2
                                            ? '客户'
                                            : ''
                                    }}</span
                                >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="联系人" :required="!isUpData">
                        <el-input
                            :disabled="isUpData"
                            v-model="form.representA"
                            placeholder="请输入"
                            style="width: 240px; margin-right: 27px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="合同金额" :required="!isUpData">
                        <el-input
                            :disabled="isUpData"
                            v-model="form.contractAmount"
                            style="width: 240px"
                            placeholder="合同金额"
                            onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="回款金额" :required="!isUpData">
                        <span style="margin-left: 15px">
                            {{ returnAmount(form) }}
                        </span>
                    </el-form-item>
                    <el-form-item label="开票金额" :required="!isUpData">
                        <span style="margin-left: 15px">
                            {{ billing(form) }}
                        </span>
                    </el-form-item>
                    <el-form-item label="支付方式" :required="!isUpData">
                        <el-select
                            :disabled="isUpData"
                            v-model="form.payWay"
                            style="width: 240px"
                            clearable
                            placeholder="请选择"
                            ><el-option label="网银" :value="1"></el-option>
                            <el-option label="现金" :value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="合同状态" :required="!isUpData">
                        <el-select
                            disabled
                            v-model="form.status"
                            placeholder="请选择"
                            size="small"
                            style="width: 180px"
                        >
                            <el-option label="作废" :value="3"></el-option>
                            <el-option label="审核中" :value="7"></el-option>
                            <el-option label="审核驳回" :value="8"></el-option>
                            <el-option label="已归档" :value="10"></el-option>
                            <el-option label="未归档" :value="11"></el-option>
                            <el-option label="待归档" :value="12"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="line">
                    <el-form-item label="甲方" :required="!isUpData">
                        <el-input
                            :disabled="isUpData"
                            v-model="form.contractA"
                            placeholder="请输入"
                            style="width: 240px; margin-right: 27px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="甲方代表" :required="!isUpData">
                        <el-input
                            :disabled="isUpData"
                            v-model="form.representA"
                            style="width: 240px"
                            placeholder="请输入"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="乙方"
                        :required="!isUpData"
                        v-show="form.typeB != 9 || !isUpData"
                    >
                        <el-select
                            :disabled="isUpData"
                            v-model="form.typeB"
                            style="width: 240px; margin-right: 27px"
                            clearable
                            placeholder="请选择"
                            ><el-option
                                v-for="item in $tableDataHandle.selectTypeB()"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="乙方签约"
                        :required="!isUpData"
                        v-show="form.typeB == 9"
                    >
                        <el-input
                            :disabled="isUpData"
                            v-model="form.contractB"
                            style="width: 240px"
                            placeholder="请输入"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="乙方代表" :required="!isUpData">
                        <el-input
                            :disabled="isUpData"
                            v-model="form.representB"
                            style="width: 240px"
                            placeholder="请输入"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="签约时间" :required="!isUpData">
                        <el-date-picker
                            :disabled="isUpData"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            v-model="form.signTime"
                            style="width: 240px; margin-right: 27px"
                            type="date"
                            placeholder="选择日期"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="到期时间">
                        <el-date-picker
                            :disabled="isUpData"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            v-model="form.expireTime"
                            style="width: 240px"
                            type="date"
                            placeholder="选择日期"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="合同类型" required>
                        <el-radio-group
                            v-model="form.saleType"
                            :disabled="isUpData"
                        >
                            <el-radio :label="1">线索</el-radio>
                            <el-radio :label="2">自拓</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="关联线索" v-show="form.saleType == 1">
                        <el-select
                            :disabled="isUpData"
                            filterable
                            v-model="form.lineId"
                            style="width: 240px; margin-right: 27px"
                            clearable
                            placeholder="请搜索选择关联线索"
                        >
                            <el-option
                                v-for="item in clueData"
                                :key="item.id"
                                :label="
                                    item.createTime +
                                    ' ' +
                                    $tableDataHandle.productCategory(
                                        item.productClue
                                    )
                                "
                                :value="item.id"
                            >
                                <span
                                    style="
                                        float: left;
                                        color: #8492a6;
                                        font-size: 13px;
                                        margin-right: 5px;
                                    "
                                    >{{
                                        item.createTime
                                            ? item.createTime.slice(0, 16)
                                            : '- -'
                                    }}</span
                                >
                                <span style="float: right">
                                    {{
                                        $tableDataHandle.productCategory(
                                            item.productClue
                                        )
                                    }}
                                </span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="line">
                    <el-form-item label="创建人">
                        <el-input
                            disabled
                            v-model="form.createAdminName"
                            placeholder="请输入"
                            style="width: 240px; margin-right: 27px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="所属人">
                        <el-input
                            disabled
                            v-model="form.adminName"
                            style="width: 240px"
                            placeholder="请输入"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="合作人">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            placement="top"
                            v-if="
                                form.chanceCollaboratorBOList &&
                                form.chanceCollaboratorBOList.length > 0
                            "
                        >
                            <div slot="content">
                                <p
                                    style="line-height: 1.5"
                                    v-for="item in form.chanceCollaboratorBOList"
                                    :key="item.id"
                                >
                                    {{ item.collaboratorAdminName }}
                                </p>
                            </div>
                            <el-input
                                disabled
                                v-model="
                                    form.chanceCollaboratorBOList[0]
                                        .collaboratorAdminName
                                "
                                style="width: 240px"
                                placeholder="请输入"
                            ></el-input>
                        </el-tooltip>
                        <el-input
                            v-else
                            disabled
                            value="- -"
                            style="width: 240px"
                            placeholder="请输入"
                        ></el-input>
                        <span class="circle_toast">{{
                            form.chanceCollaboratorBOList.length
                        }}</span>
                    </el-form-item>
                    <el-form-item label="创建时间">
                        <el-date-picker
                            disabled
                            value-format="yyyy-MM-dd HH:mm:ss"
                            v-model="form.createTime"
                            style="width: 240px"
                            type="date"
                            placeholder="选择日期"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="修改时间">
                        <el-date-picker
                            disabled
                            value-format="yyyy-MM-dd HH:mm:ss"
                            v-model="form.updateTime"
                            style="width: 240px"
                            type="date"
                            placeholder="选择日期"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="修改人">
                        <el-input
                            disabled
                            v-model="form.adminName"
                            placeholder="请输入"
                            style="width: 240px; margin-right: 27px"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="营业执照">
                        <div v-show="isUpload">
                            <el-image
                                v-if="
                                    form.businessLicenseUrl &&
                                    (form.businessLicenseUrl.indexOf('.jpg') !=
                                        -1 ||
                                        form.businessLicenseUrl.indexOf(
                                            '.png'
                                        ) != -1 ||
                                        form.businessLicenseUrl.indexOf(
                                            '.gif'
                                        ) != -1)
                                "
                                style="width: 100px; height: 100px"
                                :src="form.businessLicenseUrl"
                                :preview-src-list="[form.businessLicenseUrl]"
                            >
                            </el-image>
                            <span
                                class="pdf"
                                v-if="
                                    form.businessLicenseUrl &&
                                    (form.businessLicenseUrl.indexOf('.pdf') !=
                                        -1 ||
                                        form.businessLicenseUrl.indexOf(
                                            '.PDF'
                                        ) != -1)
                                "
                                @click="onPreview(form.businessLicenseUrl)"
                                style="cursor: pointer"
                            >
                                <img src="@/img/pdfUrl.png" alt=""
                            /></span>
                            <span
                                class="word"
                                v-if="
                                    form.businessLicenseUrl &&
                                    form.businessLicenseUrl.indexOf('.doc') !=
                                        -1
                                "
                                @click="onPreview(form.businessLicenseUrl)"
                                style="cursor: pointer"
                            >
                                <img src="@/img/word.png" alt="" />
                            </span>
                        </div>
                        <el-upload
                            :class="{ hide: hideUpload }"
                            v-show="!isUpload"
                            :disabled="isUpload"
                            :action="action"
                            class="avatar-uploader"
                            list-type="picture-card"
                            :on-success="onBusinessLicense"
                            :before-upload="beforeAvatarUploads"
                            multiple
                            drag
                            :limit="1"
                            ref="businessLicense"
                            accept=".jpg, .png, .gif, .pdf,.doc,.docx"
                            :file-list="businessLicense"
                        >
                            <i slot="default" class="el-icon-plus"></i>

                            <div
                                slot="file"
                                slot-scope="{ file }"
                                style="text-align: center"
                            >
                                <img
                                    class="el-upload-list__item-thumbnail"
                                    :src="form.businessLicenseUrl"
                                    alt=""
                                    v-if="
                                        form.businessLicenseUrl &&
                                        (form.businessLicenseUrl.indexOf(
                                            '.jpg'
                                        ) != -1 ||
                                            form.businessLicenseUrl.indexOf(
                                                '.png'
                                            ) != -1 ||
                                            form.businessLicenseUrl.indexOf(
                                                '.gif'
                                            ) != -1)
                                    "
                                />
                                <span
                                    v-if="
                                        form.businessLicenseUrl &&
                                        (form.businessLicenseUrl.indexOf(
                                            '.pdf'
                                        ) != -1 ||
                                            form.businessLicenseUrl.indexOf(
                                                '.PDF'
                                            ) != -1)
                                    "
                                >
                                    <img src="@/img/pdfUrl.png" alt="" />
                                </span>
                                <span
                                    v-if="
                                        form.businessLicenseUrl &&
                                        form.businessLicenseUrl.indexOf(
                                            '.doc'
                                        ) != -1
                                    "
                                >
                                    <img src="@/img/word.png" alt="" />
                                </span>
                                <span class="el-upload-list__item-actions">
                                    <span
                                        class="el-upload-list__item-preview"
                                        @click="handlePictureCardPreview(file)"
                                        v-if="
                                            form.businessLicenseUrl &&
                                            (form.businessLicenseUrl.indexOf(
                                                '.jpg'
                                            ) != -1 ||
                                                form.businessLicenseUrl.indexOf(
                                                    '.png'
                                                ) != -1 ||
                                                form.businessLicenseUrl.indexOf(
                                                    '.gif'
                                                ) != -1)
                                        "
                                    >
                                        <i class="el-icon-zoom-in"></i>
                                    </span>
                                    <span
                                        class="el-upload-list__item-delete"
                                        @click="handleDownload(file)"
                                    >
                                        <i class="el-icon-download"></i>
                                    </span>
                                    <span
                                        class="el-upload-list__item-delete"
                                        @click="handleRemove(file)"
                                    >
                                        <i class="el-icon-delete"></i>
                                    </span>
                                </span>
                            </div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="附件">
                        <div v-show="isUpload">
                            <span
                                style="margin-right: 8px"
                                class="cont"
                                v-show="contractAttachmentList"
                                v-for="item in contractAttachmentList"
                            >
                                <el-image
                                    v-if="
                                        item.attachmentUrl &&
                                        (item.attachmentUrl.indexOf('.jpg') !=
                                            -1 ||
                                            item.attachmentUrl.indexOf(
                                                '.png'
                                            ) != -1 ||
                                            item.attachmentUrl.indexOf(
                                                '.gif'
                                            ) != -1)
                                    "
                                    style="width: 100px; height: 100px"
                                    :src="item.attachmentUrl"
                                    :preview-src-list="[item.attachmentUrl]"
                                >
                                </el-image>
                                <span
                                    class="pdf"
                                    v-if="
                                        item.attachmentUrl &&
                                        (item.attachmentUrl.indexOf('.pdf') !=
                                            -1 ||
                                            item.attachmentUrl.indexOf(
                                                '.PDF'
                                            ) != -1)
                                    "
                                    @click="onPreview(item.attachmentUrl)"
                                    style="cursor: pointer"
                                >
                                    <img src="@/img/pdfUrl.png" alt="" />
                                </span>
                                <span
                                    class="word"
                                    v-if="
                                        item.attachmentUrl &&
                                        item.attachmentUrl.indexOf('.doc') != -1
                                    "
                                    @click="onPreview(item.attachmentUrl)"
                                    style="cursor: pointer"
                                >
                                    <img src="@/img/word.png" alt="" />
                                </span>
                            </span>
                        </div>
                        <el-upload
                            v-show="!isUpload"
                            :class="{ hide: hideUploads }"
                            drag
                            :action="action"
                            class="avatar-uploader"
                            list-type="picture-card"
                            :on-success="onAttachment"
                            :before-upload="beforeAvatarUploads"
                            multiple
                            :file-list="fileList"
                            :limit="limit"
                            ref="attachment"
                            accept=".jpg, .png, .gif, .pdf,.doc,.docx"
                        >
                            <i slot="default" class="el-icon-plus"></i>
                            <div
                                slot="file"
                                slot-scope="{ file }"
                                style="text-align: center"
                            >
                                <img
                                    class="el-upload-list__item-thumbnail"
                                    :src="file.url"
                                    v-if="
                                        file.url &&
                                        (file.url.indexOf('.jpg') != -1 ||
                                            file.url.indexOf('.png') != -1 ||
                                            file.url.indexOf('.gif') != -1)
                                    "
                                />
                                <span
                                    v-if="
                                        file.url &&
                                        (file.url.indexOf('.pdf') != -1 ||
                                            file.url.indexOf('.PDF') != -1)
                                    "
                                >
                                    <img src="@/img/pdfUrl.png" alt="" />
                                </span>
                                <span
                                    v-if="
                                        file.url &&
                                        file.url.indexOf('.doc') != -1
                                    "
                                >
                                    <img src="@/img/word.png" alt="" />
                                </span>
                                <span class="el-upload-list__item-actions">
                                    <span
                                        class="el-upload-list__item-preview"
                                        @click="handlePictureCardPreview(file)"
                                        v-if="
                                            file.url &&
                                            (file.url.indexOf('.jpg') != -1 ||
                                                file.url.indexOf('.png') !=
                                                    -1 ||
                                                file.url.indexOf('.gif') != -1)
                                        "
                                    >
                                        <i class="el-icon-zoom-in"></i>
                                    </span>
                                    <span
                                        class="el-upload-list__item-delete"
                                        @click="handleDownload(file)"
                                    >
                                        <i class="el-icon-download"></i>
                                    </span>
                                    <span
                                        class="el-upload-list__item-delete"
                                        @click="handleRemoves(file)"
                                    >
                                        <i class="el-icon-delete"></i>
                                    </span>
                                </span>
                            </div>
                        </el-upload>
                    </el-form-item>
                </div>
            </div>
        </el-form>
        <el-dialog :visible.sync="dialogVisible" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
    </div>
</template>
<script>
import { customerList } from '@/api/contr/contr.js';
import { clueList } from '@/api/clue/meClue';
import { getCustomerId } from '@/api/user/ae/customer.js';
export default {
    data() {
        return {
            data: {},
            form: {
                contractNo: '',
                representA: '',
                contractAmount: '',
                payWay: '',
                status: '',
                contractA: '',
                representA: '',
                typeB: '',
                representB: '',
                contractB: '',
                signTime: '',
                expireTime: '',
                adminName: '',
                createTime: '',
                updateTime: '',
                customerId: '',
                lineId: '',
                saleType: 0,
            },
            isUpData: true,
            customerId: '',
            customerData: [],
            isUpload: true,
            action: this.$url.url() + '/file/local/upload/contract',
            fileList: [],
            businessLicense: [],
            contractAttachmentList: [],
            dialogVisible: false,
            dialogImageUrl: '',
            limit: 5,
            fileArr: [],
            hideUpload: false,
            hideUploads: false,
            clueData: [],
            clueDisabled: true,
        };
    },
    methods: {
        getData(data) {
            this.data = data;
            this.hideUpload = false;
            this.hideUploads = false;
            this.form.contractB = data.contractB;
            this.form.contractNo = data.contractNo;
            this.form.representA = data.representA;
            this.form.contractAmount = data.contractAmount;
            this.form.payWay = data.payWay;
            this.form.status = data.status;
            this.form.contractA = data.contractA;
            this.form.representA = data.representA;
            this.form.typeB = data.typeB;
            this.form.representB = data.representB;
            this.form.signTime = data.signTime;
            this.form.expireTime = data.expireTime;
            this.form.adminName = data.adminName;
            this.form.createTime = data.createTime;
            this.form.updateTime = data.updateTime;
            this.form.id = data.id;
            this.form.createAdminName = data.createAdminName;
            this.form.chanceCollaboratorBOList = data.chanceCollaboratorBOList;
            this.customerId = this.data.customerId;
            this.form.saleType = data.saleType;
            if (data.lineId != 0) {
                this.form.lineId = data.lineId;
            }
            this.form.businessLicenseUrl = data.businessLicenseUrl;
            this.getClue();
            this.businessLicense = [];
            if (data.businessLicenseUrl) {
                this.businessLicense = [{ url: data.businessLicenseUrl }];
                this.hideUpload = true;
            }
            this.contractAttachmentList = data.contractAttachmentBOList;
            this.fileArr = data.contractAttachmentBOList;
            this.fileList = [];
            if (
                data.contractAttachmentBOList &&
                data.contractAttachmentBOList.length > 0
            ) {
                for (let i in data.contractAttachmentBOList) {
                    this.fileList.push({ url: '' });
                    this.fileList[i].url =
                        data.contractAttachmentBOList[i].attachmentUrl;
                    this.fileArr[i].url =
                        data.contractAttachmentBOList[i].attachmentUrl;
                }
            }
            if (this.fileList.length == this.limit) {
                this.hideUploads = true;
            }
        },
        getRepresentA() {
            let data = {
                param: {
                    id: this.customerId,
                },
                pageNum: 0,
                pageSize: 0,
            };
            getCustomerId(data).then((res) => {
                if (res.code == 200) {
                    if (
                        res.data.customerContactList &&
                        res.data.customerContactList.length > 0
                    ) {
                        this.form.representA =
                            res.data.customerContactList[0].contactName;
                        this.form.contractA =
                            res.data.customerContactList[0].companyName;
                    }
                }
            });
            this.form.lineId = '';
            this.getClue();
        },
        getClue() {
            let obj = {
                param: {
                    customerId: this.customerId,
                    customerStatusList: [1, 2, 3],
                },
                pageNum: 0,
                pageSize: 0,
            };
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            if (!btn.searchUser) {
                obj.param.followAdminId = sessionStorage.getItem('adminId');
            }
            clueList(obj).then((res) => {
                this.clueData = res.data.list;
            });
        },
        billing(row) {
            let nub = 0;
            if (
                row.contractReturnPlanBOList &&
                row.contractReturnPlanBOList.length > 0
            ) {
                for (let i = 0; i < row.contractReturnPlanBOList.length; i++) {
                    if (row.contractReturnPlanBOList[i].billingStatus == 2) {
                        nub += Number(
                            row.contractReturnPlanBOList[i].returnAmount
                        );
                    }
                }
            }
            return nub;
        },
        returnAmount(row) {
            let nub = 0;
            if (
                row.contractReturnPlanBOList &&
                row.contractReturnPlanBOList.length > 0
            ) {
                for (let i = 0; i < row.contractReturnPlanBOList.length; i++) {
                    if (row.contractReturnPlanBOList[i].returnStatus == 2) {
                        nub += Number(
                            row.contractReturnPlanBOList[i].returnAmount
                        );
                    }
                }
            }
            return nub;
        },
        upData(type) {
            if (
                (this.data.status == 11 || this.data.status == 8) &&
                this.data.returnStatus == 1
            ) {
                let obj = {
                    param: {
                        adminId: sessionStorage.getItem('adminId'),
                    },
                    pageNum: 1,
                    pageSize: 10,
                };
                customerList(obj).then((res) => {
                    if (res.code == 200) {
                        res.data.list.push({
                            id: this.data.customerId,
                            companyName: this.data.companyName,
                        });
                        let obj = {};
                        this.customerData = res.data.list.reduce(
                            (prev, cur) => {
                                obj[cur.id]
                                    ? ''
                                    : (obj[cur.id] = true && prev.push(cur));
                                return prev;
                            },
                            []
                        );
                        this.customerId = this.data.customerId;
                        this.getData(this.data);
                    }
                });
                this.isUpData = type;
            }
            this.isUpload = type;
            if (this.data.adminId == sessionStorage.getItem('adminId')) {
                this.clueDisabled = type;
            } else {
                this.clueDisabled = true;
            }
        },
        remoteMethod(query) {
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                },
                pageNum: 1,
                pageSize: 10,
            };
            data.param.name = query;

            customerList(data).then((res) => {
                if (res.code == 200) {
                    this.customerData = res.data.list;
                }
            });
        },
        // 添加线索
        addClue() {
            this.clueList.push({});
        },
        // 删除山所
        removeClue(index) {
            this.clueList.splice(index, 1);
        },
        // 上传营业执照
        onBusinessLicense(response) {
            if ((response.code = 200)) {
                this.form.businessLicenseUrl = response.data;
                this.$message.success('营业执照上传成功');
                this.hideUpload = true;
            } else {
                this.$message.success('营业执照上传失败');
            }
        },
        // 上传附件
        onAttachment(response) {
            this.fileArr.push({ url: response.data });
            setTimeout(() => {
                this.fileList = this.fileArr;
            }, 2000);
            if (this.fileArr.length == this.limit) {
                this.hideUploads = true;
            }
        },
        beforeAvatarUploads(file) {
            const isLt2M = file.size / 1024 / 1024 < 20;
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 20MB!');
            }
            return isLt2M;
        },
        // 预览图片
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        // 下载
        handleDownload(file) {
            if (
                file.url.indexOf('.doc') != -1 ||
                file.url.indexOf('.docx') != -1
            ) {
                window.open(
                    'https://view.officeapps.live.com/op/view.aspx?src=' +
                        file.url
                );
            } else {
                window.open(file.url, '_blank');
            }
        },
        // 营业执照删除
        handleRemove(file) {
            this.$refs['businessLicense'].uploadFiles = [];
            this.form.businessLicenseUrl = '';
            this.hideUpload = false;
        },
        // 附件删除
        handleRemoves(file) {
            for (let i in this.fileList) {
                if (file.url == this.fileList[i].url) {
                    this.fileArr.splice(i, 1);
                }
            }

            this.fileList = this.fileArr;
            this.hideUploads = false;
        },
        onPreview(url) {
            if (url.indexOf('.doc') != -1 || url.indexOf('.docx') != -1) {
                window.open(
                    'https://view.officeapps.live.com/op/view.aspx?src=' + url
                );
            } else {
                window.open(url, '_blank');
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.form_box {
    display: flex;
    margin-left: 24px;
    padding: 20px 0;
    .line {
        flex: 1;
    }
    /deep/ .avatar-uploader .el-upload {
        border: none;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background: #f3f6fb;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
    }
    /deep/.el-upload-dragger {
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
    }
    /deep/ .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    /deep/.avatar-uploader-icon {
        color: #8c939d;
        width: 100px;
        height: 100px;
        line-height: 80px;
        text-align: center;
    }
    /deep/ .el-upload-list__item.is-success {
        width: 100px;
        height: 100px;
    }
}
.circle_toast {
    background-color: #2370eb;
    color: #fff;
    display: inline-block;
    padding: 3px 5px;
    line-height: 1;
    border: 1px solid #2370eb;
    border-radius: 20px;
}
.contact_way_box {
    background-color: #fff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 0;
    margin-bottom: 10px;
    position: relative;
    button {
        border: 2px solid #2370eb;
        background: #fff;
        color: #2370eb;

        padding: 2px;
        margin-left: 8px;
    }
}
</style>
<style>
.basic .line .el-input {
    font-size: 14px !important;
}
.basic .line .el-input.is-disabled .el-input__inner {
    background: #fff !important;
}
.basic .line .el-input.is-disabled .el-input__inner::placeholder {
    display: none !important;
    color: #fff !important;
}
.basic .line .el-form-item__label {
    color: #666666 !important;
}
.hide .el-upload--picture-card {
    display: none;
}
.basic .el-image {
    width: 80px !important;
    height: 80px !important;
}
.basic .el-upload-list--picture-card .el-upload-list__item {
    width: 100px !important;
    height: 100px !important;
}
.basic .line .el-input.is-disabled .el-input__inner {
    padding-left: 15px !important;
}
</style>
