<template>
    <div>
        <div class="dataList" v-for="item in data">
            <p>
                <span>审核结果</span>
                <span :class="item.status == 1 ? 'one' : item.status == 0 ? 'two' : ''">
                    <i :class="item.status == 1 ? 'el-icon-success' : 'el-icon-error'"></i>
                    {{
                        item.status == 1 ? '审核通过' : item.status == 0 ? '审核驳回' : '- -'
                    }}</span
                >
            </p>
            <p>
                <span>审核时间</span>
                <span>{{ item.createTime }}</span>
            </p>
            <p>
                <span>审核备注</span>
                <span>{{ item.remark }}</span>
            </p>
        </div>
        <div class="nothing" v-if="data.length == 0">
            暂无数据～
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            data: [],
        };
    },
    methods: {
        getData(data) {
            this.data = data.contractAuditBOList;
        },
    },
};
</script>
<style lang="scss" scoped>
.dataList {
    display: flex;
    color: #333333;
    font-size: 13px;
    padding: 20px;
    p {
        span:nth-child(1) {
            margin-right: 24px;
        }
        span:nth-child(2) {
            margin-right: 50px;
        }
    }
    p:nth-child(1) {
        span:nth-child(2) {
            margin-right: 50px;
            display: inline-block;
            width: 104px;
            line-height: 25px;
            border-radius: 13px;
            text-align: center;
        }
        .one {
            background: #e0eaff;
            i {
                color: #82a7fc;
            }
        }
        .two {
            background: rgba(208, 2, 27, 0.08);
            color: #d0021b;
        }
    }
}
.nothing {
    padding: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}
</style>
