<template>
    <div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column prop="" width="10"> </el-table-column>
            <el-table-column label="产品类别" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span
                        v-if="
                            scope.row.productAttributeRelationBOList &&
                            scope.row.productAttributeRelationBOList.length > 0
                        "
                        >{{ scope.row.productAttributeRelationBOList[0].productName }}</span
                    >
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column label="版本" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ returnText(scope.row, 'version') }} </span>
                </template>
            </el-table-column>
            <el-table-column label="购买类型" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ returnText(scope.row, 'productType') }}</span>
                </template>
            </el-table-column>
            <el-table-column label="用户数" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span
                        v-if="
                            scope.row.productCategory != 5 &&
                            scope.row.productCategory != 6 &&
                            scope.row.productCategory != 8 &&
                            scope.row.productCategory != 9 &&
                            scope.row.productCategory != 12 &&
                            scope.row.productCategory != 3
                        "
                        >{{ scope.row.number }} 用户</span
                    >
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column label="年限" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span
                        v-if="scope.row.productCategory != 6 && scope.row.productCategory != 9"
                        <span
                        v-if="
                            scope.row.productCategory != 6 &&
                            scope.row.productCategory != 9 &&
                            scope.row.productCategory != 11 &&
                            scope.row.productType != 3
                        "
                        >{{ scope.row.term }} 年</span
                    >
                    <span v-else>- -</span>
                </template>
            </el-table-column>

            <el-table-column label="金额" prop="receivableAmount" show-overflow-tooltip>
            </el-table-column>

            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="statistics">
            <div class="total">
                <p>合计</p>
                <p>
                    {{ total }}
                    <span style="font-size: 13px">元</span>
                </p>
            </div>
            <div class="detailed">
                <p>明细</p>
                <p>{{ data.remark }}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tableData: [],
            total: 0,
            data: {},
            targetList: [],
        };
    },
    methods: {
        getData(data) {
            this.data = JSON.parse(JSON.stringify(data));
            this.tableData = JSON.parse(JSON.stringify(data.contractTargetBOList));
            let price = data.contractTargetBOList.reduce(
                (sum, e) => sum + Number(e.receivableAmount || 0),
                0,
            );
            this.total = price;
        },
        returnText(row, field) {
            console.log(row, field);
            let targetData = row.productAttributeRelationBOList;
            let text = '- -';
            for (let i = 0; i < targetData.length; i++) {
                if (targetData[i].attributeColumnName == field) {
                    for (let j = 0; j < targetData[i].productAttributeValueBOList.length; j++) {
                        if (
                            row[field] ==
                            targetData[i].productAttributeValueBOList[j].attributeValue
                        ) {
                            text = targetData[i].productAttributeValueBOList[j].attributeValueName;
                        }
                    }
                }
            }

            return text;
        },

        // productCategory(row) {
        //     console.log(this.tableData);
        //     let arr = this.$TargetList.targetList();

        //     for (let j = 0; j < arr.length; j++) {
        //         if (arr[j].value != 'zjcy') {
        //             for (let l = 0; l < arr[j].children.length; l++) {
        //                 if (row.productCategory == arr[j].children[l].value) {
        //                     return arr[j].children[l].label;
        //                 }
        //             }
        //         }
        //     }
        // },
    },
};
</script>
<style lang="scss" scoped>
.empty {
    padding: 50px 0;
}
.statistics {
    display: flex;
    padding: 12px 32px 0 23px;
    padding-bottom: 20px;
    div {
        background: #f0f4ff;
        border-radius: 7px;
    }
    .total {
        width: 230px;
        height: 85px;
        padding-left: 15px;
        font-weight: 600;
        p:nth-child(1) {
            font-size: 14px;
            color: #333333;
            margin: 12px 0;
        }
    }
    .detailed {
        flex: 1;
        margin-left: 24px;
        padding-left: 15px;
        p:nth-child(1) {
            font-size: 14px;
            color: #333333;
            margin: 12px 0;
            font-weight: 600;
        }
        .el-textarea__inner {
            border: none !important;
        }
    }
}
</style>
