var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add_box"},[_c('ContractInfo',{ref:"contractInfo"}),_c('div',{staticClass:"submit_form"},[_c('el-form',{attrs:{"label-position":"left","label-width":"80px","size":"small"}},[_c('el-form-item',{attrs:{"label":"交付时间","required":""}},[_c('el-date-picker',{staticStyle:{"width":"240px"},attrs:{"value-format":"yyyy-MM-dd HH:mm:ss","type":"date","placeholder":"选择日期"},model:{value:(_vm.form.deliverTime),callback:function ($$v) {_vm.$set(_vm.form, "deliverTime", $$v)},expression:"form.deliverTime"}})],1),(
                    (_vm.order.productCategory == 1 ||
                        _vm.order.productCategory == 2 ||
                        _vm.order.productCategory == 3 ||
                        _vm.order.productCategory == 4) &&
                        _vm.order.orderType != 3
                )?_c('el-form-item',{attrs:{"label":"数量","required":""}},[_c('el-input',{staticStyle:{"width":"240px"},attrs:{"placeholder":"输入数量"},on:{"input":function () {
                            if (!_vm.num(_vm.order)) {
                                _vm.form.term = '1';
                                this$1.$message.error(
                                    '交付数量有误，请检查后重新输入'
                                );
                            }
                            _vm.form.term = _vm.form.term.replace(/[^0-9.]/g, '');
                        }},model:{value:(_vm.form.term),callback:function ($$v) {_vm.$set(_vm.form, "term", $$v)},expression:"form.term"}}),_c('div',[_vm._v(" 剩余交付计划数量： "+_vm._s(_vm.order.orderTerm - _vm.allNum)+" ")])],1):_vm._e()],1)],1),_c('div',{staticClass:"footer_btn"},[_c('el-button',{attrs:{"size":"mini","round":""},on:{"click":_vm.onClose}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","size":"mini","round":""},on:{"click":_vm.onSubmit}},[_vm._v("保 存")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }