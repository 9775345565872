import { render, staticRenderFns } from "./plan.vue?vue&type=template&id=132da7cf&scoped=true&"
import script from "./plan.vue?vue&type=script&lang=js&"
export * from "./plan.vue?vue&type=script&lang=js&"
import style0 from "./plan.vue?vue&type=style&index=0&id=132da7cf&lang=scss&scoped=true&"
import style1 from "./plan.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "132da7cf",
  null
  
)

export default component.exports