<template>
    <div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column prop="" width="10"> </el-table-column>
            <el-table-column label="回款时间" show-overflow-tooltip width="150">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.receivedTime || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="付款户名"
                prop="contractA"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="入账公司" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.contractB || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="回款金额" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.receivedAmount || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <!-- <el-table-column label="开票状态" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.billingStatus == 1
                                ? '未开票'
                                : scope.row.billingStatus == 2
                                ? '已开票'
                                : '审批中'
                        }}
                    </div>
                </template>
            </el-table-column> -->
            <!-- <el-table-column label="操作" show-overflow-tooltip fixed="right">
                <template slot-scope="scope">
                    <div>
                        <el-button
                            style="color: #2370eb; margin-left: 0"
                            type="text"
                            v-if="
                                scope.row.billingStatus == 1 && btnP.addInvoice
                            "
                            @click="onApply(scope.row)"
                        >
                            申请发票
                        </el-button>
                    </div>
                </template>
            </el-table-column> -->

            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tableData: [],
            btnP: {},
        };
    },
    methods: {
        getWaterList(data, btnP) {
            this.tableData = data;
            this.btnP = btnP;
        },
        onApply(row) {
            this.$router.push({
                path: '/addApplyinvoice',
                query: {
                    contracId: row.contractId,
                },
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.empty {
    padding: 50px 0;
}
</style>
