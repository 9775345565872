<template>
    <div class="contract">
        <div class="switch">
            <p>
                <span class="shu"></span>
                合同信息
            </p>
            <div class="tab">
                <span
                    :class="index == 1 ? 'isColor' : ''"
                    @click="onSwitch(1, 'Basic_box')"
                    >基本资料</span
                >
                <span
                    :class="index == 2 ? 'isColor' : ''"
                    @click="onSwitch(2, 'Target_box')"
                    >合同标的</span
                >
                <span
                    :class="index == 4 ? 'isColor' : ''"
                    @click="onSwitch(4, 'Clue_box')"
                    >关联线索</span
                >
                <!-- <span
                    :class="index == 3 ? 'isColor' : ''"
                    @click="onSwitch(3, 'History_box')"
                    >审核历史</span
                > -->
            </div>
        </div>
        <div class="content">
            <div class="Basic_box">
                <div class="title">
                    <span style="margin-left: 6px">
                        <i
                            class="iconfont icon-ziliaoshouce-xianxing"
                            style="color: #2370eb"
                        ></i>
                        基本资料</span
                    >
                    <p>
                        <el-button
                            v-show="
                                !disabledBasic &&
                                isUpData &&
                                isBillingStatus &&
                                btnP.updateCon
                            "
                            @click="onBasicUpData()"
                            type="text"
                            style="margin-right: 12px; color: #333333"
                        >
                            <i
                                class="el-icon-edit-outline"
                                style="color: #2370eb"
                            ></i>
                            编辑
                        </el-button>
                        <el-button
                            v-show="disabledBasic"
                            type="text"
                            size="small"
                            style="
                                color: #2370eb;
                                background: #fff;
                                border-color: #2370eb;
                                padding: 5px 5px;
                            "
                            @click="onBasicCancel()"
                        >
                            取消
                        </el-button>
                        <el-button
                            v-show="disabledBasic"
                            type="text"
                            size="small"
                            @click="onBasicSubmit()"
                            style="
                                color: #fff;
                                background: #2370eb;
                                border-color: #2370eb;
                                padding: 5px 5px;
                            "
                        >
                            保存
                        </el-button>
                        <el-button
                            v-show="basicFold"
                            @click="basicFold = !basicFold"
                            type="text"
                            style="margin-right: 12px; color: #2162cb"
                        >
                            收起 <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button
                            v-show="!basicFold"
                            @click="basicFold = !basicFold"
                            type="text"
                            style="margin-right: 12px; color: #2162cb"
                        >
                            展开 <i class="el-icon-arrow-down"></i>
                        </el-button>
                    </p>
                </div>

                <div>
                    <el-collapse-transition>
                        <Basic v-show="basicFold" ref="basic" />
                    </el-collapse-transition>
                </div>
            </div>
            <div class="Target_box">
                <div class="title">
                    <span style="margin-left: 6px">
                        <i
                            class="iconfont icon-hetongliebiao"
                            style="color: #2370eb; font-size: 17px"
                        ></i>
                        合同标的</span
                    >
                    <p>
                        <el-button
                            v-show="btnP.updateTar && disabledTarget"
                            @click="onTargetUpData()"
                            type="text"
                            style="margin-right: 12px; color: #333333"
                        >
                            <i
                                class="el-icon-edit-outline"
                                style="color: #2370eb"
                            ></i>
                            编辑
                        </el-button>
                        <el-button
                            v-show="targetFold"
                            @click="targetFold = !targetFold"
                            type="text"
                            style="margin-right: 12px; color: #2162cb"
                        >
                            收起 <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button
                            v-show="!targetFold"
                            @click="targetFold = !targetFold"
                            type="text"
                            style="margin-right: 12px; color: #2162cb"
                        >
                            展开 <i class="el-icon-arrow-down"></i>
                        </el-button>
                    </p>
                </div>
                <div>
                    <el-collapse-transition>
                        <Target v-show="targetFold" ref="target" />
                    </el-collapse-transition>
                </div>
            </div>
            <div class="Clue_box">
                <div class="title">
                    <span style="margin-left: 6px">
                        <i
                            class="iconfont icon-qudaojinxiaocun"
                            style="color: #2370eb"
                        ></i>
                        关联线索</span
                    >
                    <p>
                        <el-button
                            class="setup"
                            v-show="disabledTarget"
                            @click="onSetClue"
                            style="margin-right: 12px"
                        >
                            设置
                        </el-button>
                    </p>
                </div>
                <div>
                    <el-collapse-transition>
                        <Clue ref="clue" />
                    </el-collapse-transition>
                </div>
            </div>
            <!-- <div class="History_box">
                <div class="title">
                    <span style="margin-left: 6px">
                        <i
                            class="iconfont icon-shenpi2"
                            style="color: #2370eb"
                        ></i>
                        审核历史</span
                    >
                    <p></p>
                </div>
                <div>
                    <el-collapse-transition>
                        <History ref="history" />
                    </el-collapse-transition>
                </div>
            </div> -->
        </div>
        <!-- 编辑 -->
        <el-drawer
            :visible.sync="drawerTarget"
            :direction="direction"
            :before-close="handleTarget"
            size="720px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    编辑标的
                </div>
            </template>
            <UpDataTarget @close="handleTarget" ref="updatatarget" />
        </el-drawer>
        <!-- 客户 -->
        <el-dialog
            :visible.sync="dialogClue"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="dialog_add_customer"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    线索关联设置
                </div>
            </template>
            <div class="dialog_info">
                <el-form
                    label-position="left"
                    style="margin: 0 auto; width: 80%"
                    size="small"
                >
                    <p class="info">
                        <label>合同客户</label>
                        {{ data.companyName || '- -' }}
                    </p>
                    <el-form-item label="关联线索" label-width="88px">
                        <el-select
                            v-model="clueId"
                            size="small"
                            filterable
                            clearable
                            style="width: 270px"
                            placeholder="请搜索或输入线索名称"
                            :filter-method="clueList"
                            @change="changeClue"
                        >
                            <el-option
                                v-for="item in clueListData"
                                :key="item.id"
                                :label="
                                    item.createTime +
                                    ' ' +
                                    $tableDataHandle.productCategory(
                                        item.productClue
                                    )
                                "
                                :value="item.id"
                            >
                                <span style="float: left">{{
                                    item.createTime
                                }}</span
                                >-
                                <span style="float: right">{{
                                    item.productClue
                                        ? $tableDataHandle.productCategory(
                                              item.productClue
                                          )
                                        : '- -'
                                }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <p class="info">
                        <label>需求</label>
                        {{ remark || '- -' }}
                    </p>
                </el-form>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    style="background: #2370eb"
                    type="primary"
                    round
                    @click="contracUpDate"
                    size="mini"
                    >确 认</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Basic from './basic/basic.vue';
import Target from './target/target.vue';
import History from './history/history.vue';
import Clue from './clue/clue.vue';
import { contracUpDate } from '@/api/contr/contr.js';
import { getClue, clueList } from '@/api/clue/depClue.js';
import UpDataTarget from './target/upData.vue';

export default {
    components: {
        Basic,
        Target,
        History,
        UpDataTarget,
        Clue,
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll, true);
    },
    data() {
        return {
            btnP: {},
            data: {},
            index: 1,
            basicFold: true,
            targetFold: true,
            disabledBasic: false,
            drawerTarget: false,
            dialogClue: false,
            disabledTarget: false,
            isBillingStatus: true,
            isUpData: false,
            direction: 'rtl',
            clueData: {},
            clueId: '',
            clueListData: [],
            remark: '',
        };
    },
    methods: {
        getData(data, btnP) {
            this.data = data;
            if (
                this.data.status == 11 ||
                this.data.status == 12 ||
                this.data.status == 8
            ) {
                this.disabledTarget = true;
            }
            if (
                this.data.status == 11 ||
                this.data.status == 12 ||
                this.data.status == 8 ||
                this.data.status == 10
            ) {
                this.isUpData = true;
            }
            for (
                let i = 0;
                i < this.data.contractReturnPlanBOList.length;
                i++
            ) {
                if (this.data.contractReturnPlanBOList[i].billingStatus == 3) {
                    this.isBillingStatus = false;
                }
            }
            this.$refs.basic.getData(data);
            this.$refs.target.getData(data);
            // this.$refs.history.getData(data);
            this.$refs.clue.getData(data);
            this.getClue();
            this.btnP = btnP;
            this.clueId = data.lineId || '';
        },
        getClue() {
            // if (this.data.lineId === 0) {
            //     this.clueData = {};
            //     return;
            // }
            // let data = {
            //     param: {
            //         id: this.data.lineId,
            //     },
            // };
            // getClue(data).then((res) => {
            //     if (res.code == 200) {
            //         this.clueData = res.data;
            //     }
            // });
        },
        changeClue(val) {
            if (val) {
                let index = this.clueListData.findIndex(
                    (item) => item.id == val
                );
                console.log(index);
                this.remark = this.clueListData[index].remark;
            } else {
                this.remark = '';
            }
        },
        onSwitch(i, id) {
            this.index = i;
            this.$el
                .querySelector('.' + id)
                .scrollIntoView({ block: 'start', behavior: 'smooth' });
        },
        // 编辑基本信息
        onBasicUpData() {
            this.disabledBasic = true;
            setTimeout(() => {
                this.$refs.basic.upData(false);
            });
        },
        onBasicCancel() {
            this.disabledBasic = false;
            setTimeout(() => {
                this.$refs.basic.upData(true);
            });
        },
        onBasicSubmit() {
            let basic = this.$refs.basic.form;
            basic.customerId = this.$refs.basic.customerId;
            basic.contractAttachmentDOList = [];
            for (let i in this.$refs.basic.fileList) {
                basic.contractAttachmentDOList.push({
                    attachmentUrl: this.$refs.basic.fileList[i].url,
                });
            }
            if (!basic.customerId) {
                return this.$message.error('请选择客户');
            } else if (!basic.contractAmount) {
                return this.$message.error('请输入合同金额');
            } else if (!basic.contractA) {
                return this.$message.error('请输入甲方');
            } else if (!basic.typeB) {
                return this.$message.error('请选择乙方代表');
            } else if (!basic.saleType) {
                return this.$message.error('请选择合同类型');
            } else {
                let data = {
                    param: {},
                };
                if (
                    this.data.status != 11 &&
                    this.data.status != 8 &&
                    this.data.returnStatus != 1
                ) {
                    data.param.lineId = basic.lineId;
                    data.param.contractAttachmentDOList =
                        basic.contractAttachmentDOList;
                } else {
                    data.param.customerId = basic.customerId;
                    data.param.contractNo = basic.contractNo;
                    data.param.representA = basic.representA;
                    data.param.contractAmount = basic.contractAmount;
                    data.param.payWay = basic.payWay;
                    data.param.status = basic.status;
                    data.param.contractA = basic.contractA;
                    data.param.representA = basic.representA;
                    data.param.typeB = basic.typeB;
                    data.param.representB = basic.representB;
                    data.param.signTime = basic.signTime;
                    data.param.expireTime = basic.expireTime;
                    data.param.contractB = basic.contractB;
                    data.param.lineId = basic.lineId;
                    data.param.saleType = basic.saleType;
                }
                data.param.id = basic.id;
                data.param.businessLicenseUrl = basic.businessLicenseUrl;
                data.param.contractAttachmentDOList =
                    basic.contractAttachmentDOList;
                if (!basic.lineId) {
                    data.param.lineId = 0;
                }
                if (basic.saleType == 2) {
                    delete data.param.lineId;
                }
                contracUpDate(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('修改成功');
                        this.onBasicCancel();
                        this.$emit('getContrac');
                    }
                });
            }
        },

        // 编辑标的
        onTargetUpData() {
            this.drawerTarget = true;
            setTimeout(() => {
                this.$refs.updatatarget.getData(this.data);
            });
        },

        onSetClue() {
            this.dialogClue = true;
            this.clueList();
        },
        clueList(name) {
            let data = {
                param: {
                    customerId: this.data.customerId,
                    followAdminId: this.data.adminId,
                    statusList: [5, 8],
                },
                pageSize: 10,
                pageNum: 1,
            };
            if (name) {
                data.param.name = name;
            }
            clueList(data).then((res) => {
                if (res.code == 200) {
                    this.clueListData = res.data.list;
                    this.changeClue(this.data.lineId);
                }
            });
        },
        contracUpDate() {
            let data = {
                param: {
                    customerId: this.data.customerId,
                    id: this.data.id,
                },
            };
            if (this.clueId) {
                data.param.lineId = this.clueId;
            } else {
                data.param.lineId = 0;
            }
            contracUpDate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleClose();
                }
            });
        },
        handleTarget() {
            this.drawerTarget = false;
            this.$emit('getContrac');
        },

        handleTarget() {
            this.drawerTarget = false;
            this.$emit('getContrac');
        },
        handleClose() {
            this.dialogClue = false;
            this.$emit('getContrac');
        },
    },
    beforeDestroy: function () {
        window.removeEventListener('scroll', this.handleScroll);
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.contract {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 0px;
    .switch {
        background: #fff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 13px;
        color: #333333;
        padding: 12px 13px 12px 0;
        box-shadow: 0px 12px 8px -12px #ccc;
        z-index: 5;
        .shu {
            width: 2px;
            height: 16px;
            background: #2370eb;
            display: inline-block;
            margin-right: 22px;
        }
        .tab {
            span {
                width: 72px;
                height: 20px;
                border-radius: 2px;
                background: rgba(35, 112, 235, 0.16);
                font-size: 12px;
                line-height: 20px;
                color: #333;
                margin-left: 8px;
                text-align: center;
                display: inline-block;
                cursor: pointer;
                border-radius: 10px;
            }
            .isColor {
                background-color: #2370eb;
                color: #fff;
            }
        }
    }
    .content {
        flex: 1;
        overflow: auto;
        padding-bottom: 20px;
        .title {
            height: 49px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #eeeeee;
            font-size: 13px;
            background: #fff;
        }
        .Basic_box {
            margin-top: 12px;
            background: #fff;
        }
        .Target_box {
            margin-top: 12px;
            background: #fff;
        }
        .History_box {
            margin-top: 12px;
            background: #fff;
        }
        .Clue_box {
            margin-top: 12px;
            background: #fff;
        }
    }
}
.setup {
    border: 1px solid #2370eb;
    background-color: #fff;
    color: #2370eb;
    padding: 3px 15px;
}
.dialog_info {
    width: 80%;
    margin: 25px auto 150px;
    text-align: center;
    .info {
        font-size: 14px;
        margin-bottom: 16px;
        text-align: left;
        label {
            display: inline-block;
            width: 88px;
            font-weight: bold;
            color: #333;
            margin-left: 2px;
        }
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
</style>
