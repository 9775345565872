import http from '@/utils/http.js'

// 线索(列表)
export function clueList(data) {
    return http({
        url: "jasoboss/clue/line/list",
        method: "post",
        data
    })
}
// 线索(单个)
export function getClue(data) {
    return http({
        url: "jasoboss/clue/line/get",
        method: "post",
        data
    })
}
// 线索(修改)
export function updateClue(data) {
    return http({
        url: "jasoboss/clue/line/update",
        method: "post",
        data
    })
}
// 线索历史
export function clueHistory(data) {
    return http({
        url: "/jasoboss/clue/line/history/list",
        method: "post",
        data
    })
}
// 线索往来列表
export function clueDealingList(data) {
    return http({
        url: "/jasoboss/clue/line/intercourse/list",
        method: "post",
        data
    })
}
// 线索分配部门
export function allotDepartment(data) {
    return http({
        url: "/jasoboss/clue/line/allot/department",
        method: "post",
        data
    })
}
// 线索指导人
export function personConfigGet(data) {
    return http({
        url: "/jasoboss/person/config/get",
        method: "post",
        data
    })
}
// 往来添加说明
export function dealingDesAdd(data) {
    return http({
        url: "/jasoboss/clue/line/intercourse/describe/add",
        method: "post",
        data
    })
}
// 市场线索数据统计
export function clueBrief(data) {
    return http({
        url: "/jasoboss/clue/line/get/brief",
        method: "post",
        data
    })
}