<template>
    <div class="invoice_info">
        <div class="statistics">
            <p>
                <span>合同金额</span>
                <span>{{ contractData.contractAmount }}</span>
            </p>
            <p>
                <span>申请开票金额</span>
                <span>{{ apply(contractData) }}</span>
            </p>
            <p>
                <span>已开票金额</span>
                <span>{{ Invoiced(contractData) }}</span>
            </p>
        </div>
        <div class="content_list" v-for="(item, index) in data">
            <p class="title">
                开票记录 {{ index + 1 }}
                <span
                    :class="
                        item.status == 1
                            ? 'one'
                            : item.status == 2
                            ? 'two'
                            : item.status == 3
                            ? 'three'
                            : item.status == 4
                            ? 'four'
                            : ''
                    "
                >
                    <i
                        :class="
                            item.status == 1
                                ? 'el-icon-warning'
                                : item.status == 2
                                ? 'el-icon-success'
                                : item.status == 3
                                ? 'el-icon-error'
                                : item.status == 4
                                ? 'iconfont icon-chexiao-tianchong'
                                : ''
                        "
                    ></i>
                    {{
                        item.status == 1
                            ? '待审批'
                            : item.status == 2
                            ? '已通过'
                            : item.status == 3
                            ? '已驳回'
                            : item.status == 4
                            ? '已撤销'
                            : item.status == 5
                            ? '已开票'
                            : ''
                    }}</span
                >
                <!-- <el-button
                    v-show="item.status == 4 && item.isUpData"
                    @click="onRefresh(item)"
                    type="text"
                    style="margin-left: 20px; color: #333333"
                >
                    <i class="el-icon-edit-outline" style="color: #2370eb"></i>
                    编辑
                </el-button>
                <el-button
                    v-show="!item.isUpData"
                    type="text"
                    size="small"
                    style="
                        margin-left: 20px;
                        color: #2370eb;
                        background: #fff;
                        border-color: #2370eb;
                        padding: 5px 5px;
                    "
                    @click="onClose(item)"
                >
                    取消
                </el-button>
                <el-button
                    v-show="!item.isUpData"
                    type="text"
                    size="small"
                    @click="onSubmit(item)"
                    style="
                        color: #fff;
                        background: #2370eb;
                        border-color: #2370eb;
                        padding: 5px 5px;
                    "
                >
                    保存
                </el-button> -->
            </p>

            <el-form label-width="90px" label-position="left" size="small">
                <div class="list_box">
                    <div class="list_form">
                        <el-form-item label="申请时间">
                            <el-input
                                :disabled="item.isUpData"
                                v-model="item.createTime"
                                placeholder="请输入"
                                style="width: 240px; margin-right: 27px"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="申请人">
                            <el-input
                                disabled
                                v-model="item.adminName"
                                placeholder="请输入"
                                style="width: 240px; margin-right: 27px"
                            ></el-input>
                        </el-form-item>

                        <el-form-item label="开票金额">
                            <el-input
                                :disabled="item.isUpData"
                                v-model="item.invoiceAmount"
                                placeholder="请输入"
                                style="width: 240px; margin-right: 27px"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="开票类别">
                            <el-select
                                :disabled="item.isUpData"
                                v-model="item.invoiceCategory"
                                style="width: 240px"
                                placeholder="请选择"
                            >
                                <el-option label="电子发票" :value="1">
                                </el-option>
                                <el-option label="纸质发票" :value="2">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="开票类型">
                            <el-select
                                :disabled="item.isUpData"
                                v-model="item.invoiceType"
                                style="width: 240px"
                                placeholder="请选择"
                            >
                                <el-option label="增值税普通发票" :value="1">
                                </el-option>
                                <el-option label="增值税专用发票" :value="2">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="收件人">
                            <el-input
                                :disabled="item.isUpData"
                                v-model="item.receiveName"
                                placeholder="请输入"
                                style="width: 240px; margin-right: 27px"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="收件邮箱">
                            <el-input
                                :disabled="item.isUpData"
                                v-model="item.receiveEmail"
                                placeholder="请输入"
                                style="width: 240px; margin-right: 27px"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="收件电话">
                            <el-input
                                :disabled="item.isUpData"
                                v-model="item.receivePhone"
                                placeholder="请输入"
                                style="width: 240px; margin-right: 27px"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="收件地址">
                            <el-input
                                :disabled="item.isUpData"
                                v-model="item.receiveAddress"
                                placeholder="请输入"
                                style="width: 240px; margin-right: 27px"
                            ></el-input>
                        </el-form-item>
                    </div>
                    <div class="list_form">
                        <el-form-item label="申请编号">
                            <el-input
                                :disabled="item.isUpData"
                                v-model="item.invoiceNo"
                                placeholder="请输入"
                                style="width: 240px; margin-right: 27px"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="开票名称">
                            <el-input
                                :disabled="item.isUpData"
                                v-model="item.invoiceName"
                                placeholder="请输入"
                                style="width: 240px; margin-right: 27px"
                            ></el-input>
                            <span
                                @click="copyText(item.invoiceName)"
                                style="
                                    margin-left: 20px;
                                    color: #2370eb;
                                    cursor: pointer;
                                "
                                >复制</span
                            >
                        </el-form-item>
                        <el-form-item label="签署公司">
                            <el-input
                                :disabled="item.isUpData"
                                v-model="item.contractB"
                                placeholder="请输入"
                                style="width: 240px; margin-right: 27px"
                            ></el-input>
                            <span
                                @click="copyText(item.contractB)"
                                style="
                                    margin-left: 20px;
                                    color: #2370eb;
                                    cursor: pointer;
                                "
                                >复制</span
                            >
                        </el-form-item>
                        <el-form-item label="税号">
                            <el-input
                                :disabled="item.isUpData"
                                v-model="item.dutyNo"
                                placeholder="请输入"
                                style="width: 240px; margin-right: 27px"
                            ></el-input>
                            <span
                                @click="copyText(item.dutyNo)"
                                style="
                                    margin-left: 20px;
                                    color: #2370eb;
                                    cursor: pointer;
                                "
                                >复制</span
                            >
                        </el-form-item>
                        <el-form-item
                            label="注册地址"
                            v-show="item.invoiceType == 2"
                        >
                            <el-input
                                :disabled="item.isUpData"
                                v-model="item.registerAddress"
                                placeholder="请输入"
                                style="width: 240px; margin-right: 27px"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="公司电话"
                            v-show="item.invoiceType == 2"
                        >
                            <el-input
                                :disabled="item.isUpData"
                                v-model="item.companyPhone"
                                placeholder="请输入"
                                style="width: 240px; margin-right: 27px"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="开户银行"
                            v-show="item.invoiceType == 2"
                        >
                            <el-input
                                :disabled="item.isUpData"
                                v-model="item.depositBank"
                                placeholder="请输入"
                                style="width: 240px; margin-right: 27px"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="银行账号"
                            v-show="item.invoiceType == 2"
                        >
                            <el-input
                                :disabled="item.isUpData"
                                v-model="item.bankAccount"
                                placeholder="请输入"
                                style="width: 240px; margin-right: 27px"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="快递单号">
                            <el-input
                                :disabled="item.isUpData"
                                v-model="item.expressNo"
                                placeholder="请输入"
                                style="width: 240px; margin-right: 27px"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="备注">
                            <el-input
                                :disabled="item.isUpData"
                                type="textarea"
                                style="width: 290px"
                                placeholder="输入备注"
                                v-model="item.remark"
                                :rows="3"
                            ></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div
                    class="history_box"
                    v-if="
                        item.contractInvoiceAuditBOList &&
                        item.contractInvoiceAuditBOList.length > 0
                    "
                >
                    <p>审核历史</p>
                    <el-table
                        class="table-box"
                        ref="multipleTable"
                        :data="item.contractInvoiceAuditBOList"
                        :header-cell-style="{
                            background: '#FAFAFA',
                            color: '#333333',
                            'font-size': '14px',
                            'font-weight': '500',
                        }"
                    >
                        <el-table-column prop="" width="10"> </el-table-column>
                        <el-table-column
                            label="审核时间"
                            prop="createTime"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column label="审核结果" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span>{{
                                    scope.row.status == 1
                                        ? '通过'
                                        : scope.row.status == 0
                                        ? '驳回'
                                        : '- -'
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="审核备注"
                            prop="remark"
                            show-overflow-tooltip
                        >
                        </el-table-column>

                        <div slot="empty" class="empty">
                            <img src="@/assets/zanwushuju.png" />
                            <span style="display: block">暂无数据～</span>
                        </div>
                    </el-table>
                </div>
            </el-form>
        </div>
    </div>
</template>
<script>
import { invoiceList, invoiceUpdate } from '@/api/contr/invoice.js';
export default {
    data() {
        return {
            data: [],
            isUpData: true,
            contractData: {},
        };
    },
    methods: {
        getData(data) {
            this.contractData = data;
            this.tableData = data.contractInvoiceAuditBOList;

            let obj = {
                param: {
                    contractId: data.id,
                },
            };
            invoiceList(obj).then((res) => {
                if (res.code == 200) {
                    for (let i = 0; i < res.data.list.length; i++) {
                        res.data.list[i].isUpData = true;
                    }
                    this.data = res.data.list;
                }
            });
        },
        apply(row) {
            let nub = 0;
            if (
                row.contractInvoiceBOList &&
                row.contractInvoiceBOList.length > 0
            ) {
                for (let i = 0; i < row.contractInvoiceBOList.length; i++) {
                    if (row.contractInvoiceBOList[i].status == 1) {
                        nub += Number(
                            row.contractInvoiceBOList[i].invoiceAmount
                        );
                    }
                }
            }
            return nub;
        },
        Invoiced(row) {
            let nub = 0;
            if (
                row.contractInvoiceBOList &&
                row.contractInvoiceBOList.length > 0
            ) {
                for (let i = 0; i < row.contractInvoiceBOList.length; i++) {
                    if (row.contractInvoiceBOList[i].status == 2) {
                        nub += Number(
                            row.contractInvoiceBOList[i].invoiceAmount
                        );
                    }
                }
            }
            return nub;
        },
        onRefresh(row) {
            for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].id == row.id) {
                    this.data[i].isUpData = false;
                }
            }
        },

        onSubmit(row) {
            if (row.invoiceAmount > this.contractData.contractAmount) {
                return '发票金额不能大于合同金额';
            }
            let a = JSON.stringify(row);
            let b = JSON.parse(a);
            let data = {
                param: b,
            };
            data.param.status = 1;
            invoiceUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('编辑成功，已重新提交');
                    this.onClose(row);
                }
            });
        },
        onClose(row) {
            for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].id == row.id) {
                    this.data[i].isUpData = true;
                }
            }
            this.getData(this.contractData);
        },
        copyText(data) {
            let url = data;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand('Copy'); // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success',
            });
            oInput.remove();
        },
    },
};
</script>
<style lang="scss" scoped>
.invoice_info {
    height: 100%;
    background: #fff;
    .statistics {
        margin: 11px 20px 0 20px;
        background: #f0f4ff;
        border-radius: 7px;
        display: flex;
        font-size: 13px;
        padding: 14px 20px;
        p {
            margin-right: 40px;
            span:nth-child(1) {
                color: #333333;
                margin-right: 20px;
            }
            span:nth-child(2) {
                color: #2370eb;
            }
        }
    }
    .content_list {
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        .title {
            font-size: 13px;
            padding: 16px 0;
            color: #333333;
            padding-left: 40px;
            span {
                margin-left: 18px;
                display: inline-block;
                width: 88px;
                line-height: 25px;
                border-radius: 13px;
                text-align: center;
            }
            .one {
                background: #fde9c7;
                .el-icon-warning {
                    color: #f7b84f;
                }
            }
            .two {
                background: #e0eaff;
                .el-icon-success {
                    color: #82a7fc;
                }
            }
            .three {
                background: rgba(208, 2, 27, 0.08);
                i {
                    color: #d0021b;
                }
            }
            .four {
                background: rgba(35, 112, 235, 0.16);
                i {
                    color: #2370eb;
                }
            }
        }
        .list_box {
            display: flex;
        }
        .list_form {
            flex: 1;
            padding-left: 40px;
        }
        .list_form:last-child {
            flex: 1.5;
        }
    }
    .content_list:last-child {
        border: none;
    }
    .history_box {
        p {
            font-size: 14px;
            font-weight: 600;
            padding: 18px 0 18px 40px;
        }
    }
}
.empty {
    padding: 50px 0;
}
</style>
<style>
.invoice_info .content_list .el-input.is-disabled .el-input__inner {
    background: #fff !important;
}
.invoice_info
    .content_list
    .el-input.is-disabled
    .el-input__inner::placeholder {
    display: none !important;
    color: #fff !important;
}
.invoice_info .content_list .el-form-item__label {
    color: #666666 !important;
}
.invoice_info .is-disabled .el-textarea__inner {
    background: #fff !important;
}
</style>
