<template>
    <div class="contact_info_box">
        <p class="info">
            <span>订单编号</span>
            {{ order.orderNo ? order.orderNo : '- -' }}
        </p>
        <p class="info">
            <span>客户名称</span>
            {{ order.companyName ? order.companyName : '- -' }}
        </p>
        <p class="info">
            <span>应收金额</span>
            {{ order.receivableAmount ? order.receivableAmount : '- -' }}
        </p>
    </div>
</template>

<script>
import { contractList } from '@/api/contr/contr.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            order: {},
        };
    },
    components: {},
    created() {},
    methods: {
        getData(row) {
            this.order = row;
            // console.log(row);
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.contact_info_box {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    padding-bottom: 16px;
    .title {
        line-height: 1.2;
        margin-top: 12px;
        margin-bottom: 12px;
        font-size: 16px;
        color: #333;
        padding-left: 10px;
        > i {
            color: #2370eb;
            font-size: 18px;
        }
        button {
            background: rgb(255, 255, 255);
            color: rgb(35, 112, 235);
            border-color: rgb(35, 112, 235);
            padding: 5px 10px;
            float: right;
            margin-right: 20px;
        }
    }
    .el-form {
        margin-left: 25px;
        /deep/ .el-form-item__label {
            font-weight: 600 !important;
        }
    }
    .info {
        font-size: 14px;
        color: #333;
        padding-left: 25px;
        box-sizing: border-box;
        margin-top: 15px;
        span {
            color: #666666;
            display: inline-block;
            width: 80px;
        }
    }
}
</style>
