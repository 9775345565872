import http from '@/utils/http.js'

// 列表
export function arriveList(data) {
     return http({
          url: "/jasoboss/arrive/wei/xin/list",
          method: "post",
          data
     })
}
// 单个
export function arriveGet(data) {
     return http({
          url: "/jasoboss/arrive/wei/xin/get",
          method: "post",
          data
     })
}
// 修改
export function arriveUpdate(data) {
     return http({
          url: "/jasoboss/arrive/wei/xin/update",
          method: "post",
          data
     })
}
// 匹配
export function arriveMatch(data) {
     return http({
          url: "/jasoboss/arrive/wei/xin/match",
          method: "post",
          data
     })
}
// 匹配
export function arriveMatchList(data) {
     return http({
          url: "/jasoboss/contract/return/plan/arrive/match/list",
          method: "post",
          data
     })
}
// 匹配确认
export function arriveMatchSub(data) {
     return http({
          url: "/jasoboss/contract/return/plan/arrive/match",
          method: "post",
          data
     })
}