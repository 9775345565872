<template>
    <div class="clue">
        <p>
            <span>客户名称 &nbsp; &nbsp;</span>
            <span>{{ data.companyName ? data.companyName : '- -' }}</span>
        </p>
        <p>
            <span>线索产品 &nbsp; &nbsp;</span>
            <span>{{
                data.productClue
                    ? $tableDataHandle.productCategory(data.productClue)
                    : '- -'
            }}</span>
        </p>
        <p>
            <span>创建时间 &nbsp; &nbsp;</span>
            <span>{{ data.createTime ? data.createTime : '- -' }}</span>
        </p>
        <p>
            <span>需求 &nbsp; &nbsp;</span>
            <span>{{ data.remark ? data.remark : '- -' }}</span>
        </p>
    </div>
</template>
<script>
import { getClue } from '@/api/clue/depClue.js';
export default {
    data() {
        return {
            data: {},
        };
    },
    methods: {
        getData(data) {
            if (data.lineId != 0) {
                let obj = {
                    param: {
                        id: data.lineId,
                    },
                };
                getClue(obj).then((res) => {
                    if (res.code == 200) {
                        console.log(res.data);
                        this.data = res.data;
                    }
                });
            } else {
                this.data = {};
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.clue {
    display: flex;
    padding: 30px 10px;

    p {
        display: flex;
        flex: 1;
        span:nth-child(1) {
            color: #666;
            font-size: 14px;
            font-weight: 600;
        }
        span:nth-child(2) {
            color: #333;
            display: block;
            font-size: 14px;
        }
    }
}
</style>
