<template>
    <div class="add_box">
        <ContractInfo ref="contractInfo"></ContractInfo>
        <div class="submit_form">
            <el-form label-position="left" label-width="80px" size="small">
                <el-form-item label="交付时间" required>
                    <el-date-picker
                        style="width:240px"
                        v-model="form.deliverTime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="date"
                        placeholder="选择日期"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    label="数量"
                    required
                    v-if="
                        (order.productCategory == 1 ||
                            order.productCategory == 2 ||
                            order.productCategory == 3 ||
                            order.productCategory == 4) &&
                            order.orderType != 3
                    "
                >
                    <el-input
                        style="width:240px"
                        placeholder="输入数量"
                        v-model="form.term"
                        @input="
                            () => {
                                if (!num(order)) {
                                    form.term = '1';
                                    this.$message.error(
                                        '交付数量有误，请检查后重新输入'
                                    );
                                }
                                form.term = form.term.replace(/[^0-9.]/g, '');
                            }
                        "
                    ></el-input>
                    <div>
                        剩余交付计划数量：
                        {{ order.orderTerm - allNum }}
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <!-- if (!num(order)) {
                  form.term = '0';
                } -->
        <div class="footer_btn">
            <el-button size="mini" round @click="onClose">取 消</el-button>
            <el-button type="primary" size="mini" @click="onSubmit" round
                >保 存</el-button
            >
        </div>
    </div>
</template>

<script>
import { addOrderPlan } from '@/api/order/order.js';
import ContractInfo from './contactInfo.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            form: {
                term: '1',
            },
            order: '',
            allNum: 0,
        };
    },
    components: {
        ContractInfo,
    },
    created() {},
    methods: {
        getData(row) {
            this.order = row;
            this.$refs.contractInfo.getData(row);
            var num = 0;
            row.orderPlanBOList.forEach((itm) => {
                num += Number(itm.term);
            });
            this.allNum = num;
        },
        onClose() {
            this.$emit('close', this.order.contractId);
            this.form = {};
        },
        onSubmit() {
            let data = {
                param: {
                    orderId: this.order.id,
                    deliverTime: this.form.deliverTime,
                },
            };
            if (!this.form.deliverTime) {
                return this.$message.error('请选择交付时间');
            }
            if (
                (this.order.productCategory == 1 ||
                    this.order.productCategory == 2 ||
                    this.order.productCategory == 3 ||
                    this.order.productCategory == 4) &&
                this.order.orderType != 3
            ) {
                if (this.form.term == 0 || this.form.term == null) {
                    return this.$message.error('请输入数量');
                } else {
                    if (this.num(this.order)) {
                        data.param.term = this.form.term;
                    } else {
                        return this.$message.error('交付计划数量小于订单数量');
                    }
                }
            }
            addOrderPlan(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        num(item) {
            // console.log(item);
            if (
                item.productCategory == 1 ||
                item.productCategory == 2 ||
                item.productCategory == 3 ||
                item.productCategory == 4
            ) {
                var num = 0;
                item.orderPlanBOList.forEach((itm) => {
                    num += Number(itm.term);
                });
                return num + Number(this.form.term) - item.orderTerm > 0
                    ? false
                    : true;
            } else {
                if (item.orderPlanBOList.length > 0) {
                    return false;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.add_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: #f0f0f0;
    .footer_btn {
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: right;
        border-top: 1px solid #e5e5e5;
        background-color: #fff;
        .el-button--primary {
            background-color: #2370eb;
            border-color: #2370eb;
        }
        button:last-child {
            margin-right: 30px;
        }
    }
    .submit_form {
        margin-top: 8px;
        flex: 1;
        background-color: #fff;
        padding-top: 16px;
        .el-form {
            margin-left: 25px;
            /deep/ .el-form-item__label {
                font-weight: 600 !important;
            }
        }
    }
}
.el-form-item--small.el-form-item {
    margin-bottom: 15px !important;
}
</style>
