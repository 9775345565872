<template>
    <div class="upData">
        <div style="flex: 1">
            <Target ref="target" />
        </div>
        <div class="bottom-but">
            <span style="margin-left: auto">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="background: none; border-color: #d5d6df; color: #666666"
                    @click="onClose"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 20px;
                    "
                    size="mini"
                    @click="onSubmit"
                    >保存</el-button
                ></span
            >
        </div>
    </div>
</template>
<script>
import Target from '../../../conlist/editCont/target.vue';
import { contracUpDate } from '@/api/contr/contr.js';
export default {
    components: {
        Target,
    },
    data() {
        return {
            data: {},
        };
    },
    methods: {
        getData(row) {
            this.data = row;
            this.$refs.target.getData(JSON.parse(JSON.stringify(row)));
        },
        onClose() {
            this.$emit('close');
        },
        onSubmit() {
            let target = this.$refs.target.data;
            let fieldList = this.$refs.target.fieldList;
            for (let i = 0; i < target.length; i++) {
                if (!target[i].productCategory) {
                    return this.$message.error('请选择产品类别');
                }
                for (let j = 0; j < fieldList[i].length; j++) {
                    console.log(fieldList[i][j]);
                    console.log(target[i][fieldList[i][j].attributeColumnName]);
                    if (!target[i][fieldList[i][j].attributeColumnName]) {
                        return this.$message.error(fieldList[i][j].attributeName + '不能为空');
                    }
                }
            }
            if (this.$refs.target.$data.total != this.data.contractAmount) {
                return this.$message.error('合计金额应等于合同金额');
            }
            let data = {
                param: {},
            };
            data.param.contractTargetDOList = target;
            data.param.remark = this.$refs.target.remark;
            data.param.id = this.data.id;
            contracUpDate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('修改成功');
                    this.onClose();
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.upData {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .bottom-but {
        width: 100%;
        height: 48px;
        background: #fff;
        display: flex;
        align-items: center;
        border-top: 1px solid #e5e5e5;
        button {
            width: 90px;
        }
    }
}
</style>
