import http from '@/utils/http.js'

// 订单列表
export function orderList(data) {
     return http({
          url: "/jasoboss/order/list",
          method: "post",
          data
     })
}
// 订单渠道导出
export function orderChannelEmail(data) {
     return http({
          url: "/jasoboss/order/export",
          method: "post",
          data
     })
}
// 订单列表
export function orderGet(data) {
     return http({
          url: "/jasoboss/order/get",
          method: "post",
          data
     })
}
// 交付计划添加
export function addOrderPlan(data) {
     return http({
          url: "/jasoboss/order/plan/add",
          method: "post",
          data
     })
}
// 交付计划修改
export function updateOrderPlan(data) {
     return http({
          url: "/jasoboss/order/plan/update",
          method: "post",
          data
     })
}
//单个订单交付计划
export function oneOrderPlan(data) {
     return http({
          url: "/jasoboss/order/plan/get",
          method: "post",
          data
     })
}
// 订单交付计划列表
export function orderPlanList(data) {
     return http({
          url: "/jasoboss/order/plan/list",
          method: "post",
          data
     })
}
// 删除单个订单交付计划
export function delOrderPlan(data) {
     return http({
          url: "/jasoboss/order/plan/delete",
          method: "post",
          data
     })
}
// 批量删除单个订单交付计划
export function delMoreOrderPlan(data) {
     return http({
          url: "/jasoboss/order/plan/more/delete",
          method: "post",
          data
     })
}
// 订单修改
export function orderUpdate(data) {
     return http({
          url: "/jasoboss/order/update",
          method: "post",
          data
     })
}

// 交付订单修改
export function orderPlanUpdate(data) {
     return http({
          url: "/jasoboss/order/plan/update",
          method: "post",
          data
     })
}
// 交付订单邮件
export function orderPlanEmail(data) {
     return http({
          url: "/jasoboss/order/plan/email",
          method: "post",
          data
     })
}
// 刷新状态
export function orderUpdateStatus(data) {
     return http({
          url: "/jasoboss/order/auto/update/status",
          method: "post",
          data
     })
}
// 导入交付订单
export function orderPlanImport(data) {
     return http({
          url: "/jasoboss/order/plan/import",
          method: "post",
          data
     })
}
// 交付订单日志
export function orderPlanLog(data) {
     return http({
          url: "/jasoboss/order/plan/log/list",
          method: "post",
          data
     })
}