<template>
    <div class="money">
        <div class="switch">
            <p>
                <span class="shu"></span>
                回款
            </p>
            <div class="tab">
                <span
                    :class="index == 1 ? 'isColor' : ''"
                    @click="onSwitch(1, 'Plan_box')"
                    >回款计划</span
                >
                <span
                    :class="index == 2 ? 'isColor' : ''"
                    @click="onSwitch(2, 'Info_box')"
                    >回款信息</span
                >
            </div>
        </div>
        <div class="content">
            <div class="Plan_box">
                <div class="title">
                    <span style="margin-left: 6px">
                        <i
                            class="iconfont icon-huikuanjilu"
                            style="color: #2370eb"
                        ></i>
                        回款计划</span
                    >
                    <p>
                        <!-- <el-button
                            @click="onApply"
                            type="text"
                            style="
                                background: #fff;
                                border-radius: 2px;
                                padding: 0 7px 0 12px;
                                height: 24px;
                                color: #2370eb;
                                border-color: #2370eb;
                            "
                            v-if="
                                btnP.addInvoice &&
                                oneContract.billingStatus != 3
                            "
                        >
                            + 申请发票
                        </el-button> -->
                        <el-button
                            @click="onAdd"
                            type="text"
                            style="
                                background: #fff;
                                border-radius: 2px;
                                padding: 0 7px 0 12px;
                                height: 24px;
                                color: #2370eb;
                                border-color: #2370eb;
                            "
                            v-if="
                                btnP.addMoney &&
                                allMoney < oneContract.contractAmount
                            "
                        >
                            + 新增计划
                        </el-button>
                        <el-button
                            v-show="planFold"
                            @click="planFold = !planFold"
                            type="text"
                            style="margin-right: 12px; color: #2162cb"
                        >
                            收起 <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button
                            v-show="!planFold"
                            @click="planFold = !planFold"
                            type="text"
                            style="margin-right: 12px; color: #2162cb"
                        >
                            展开 <i class="el-icon-arrow-down"></i>
                        </el-button>
                    </p>
                </div>
                <div>
                    <el-collapse-transition>
                        <Plan
                            v-show="planFold"
                            @allOnMoney="allOnMoney"
                            ref="plan"
                            @edit="edit"
                            @del="del"
                            @getMoney="getMoney"
                        />
                    </el-collapse-transition>
                </div>
            </div>
            <div class="Info_box">
                <div class="title">
                    <span style="margin-left: 6px">
                        <i
                            class="iconfont icon-hetongliebiao"
                            style="color: #2370eb; font-size: 17px"
                        ></i>
                        回款信息</span
                    >
                    <p></p>
                </div>
                <div>
                    <el-collapse-transition>
                        <Info ref="info" />
                    </el-collapse-transition>
                </div>
            </div>
            <div class="Info_box">
                <div class="title">
                    <span style="margin-left: 6px">
                        <i
                            class="iconfont icon-hetongliebiao"
                            style="color: #2370eb; font-size: 17px"
                        ></i>
                        企业微信回款信息</span
                    >
                    <p></p>
                </div>
                <div class="table_box">
                    <el-table
                        class="table-box"
                        ref="multipleTable"
                        :data="matchingData"
                        height="400px"
                        :header-cell-style="{
                            background: '#FAFAFA',
                            color: '#333333',
                            'font-size': '14px',
                            'font-weight': '500',
                        }"
                    >
                        <el-table-column prop="" width="10"> </el-table-column>
                        <el-table-column label="交易时间" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.tradeTime || '- -' }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="交易单号" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.tradeNo || '- -' }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="付款人" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.externalUserName || '- -' }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="收款总金额"
                            show-overflow-tooltip
                        >
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.amount || '- -' }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="收款人" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.adminName || '- -' }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="收款备注" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.remark || '- -' }}
                                </div>
                            </template>
                        </el-table-column>
                        <div slot="empty" class="empty">
                            <img src="@/assets/zanwushuju.png" />
                            <span style="display: block">暂无数据～</span>
                        </div>
                    </el-table>
                </div>
            </div>
        </div>
        <el-dialog
            :visible.sync="dialog"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="dilog_box"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    {{ isEidt ? '编辑' : '新增' }}计划
                </div>
            </template>
            <el-form label-position="left" label-width="80px" size="small">
                <el-form-item label="回款金额" required>
                    <el-input
                        style="width: 240px"
                        placeholder="请输入"
                        v-model="form.returnAmount"
                        @input="
                            () => {
                                if (
                                    form.returnAmount - onePLan.returnAmount >
                                    oneContract.contractAmount - allMoney
                                ) {
                                    form.returnAmount = '0';
                                    this.$message.error(
                                        '回款金额必须小于合同金额'
                                    );
                                }
                                form.returnAmount = form.returnAmount.replace(
                                    /[^0-9.]/g,
                                    ''
                                );
                            }
                        "
                    ></el-input>
                    <div>
                        剩余回款计划金额：{{
                            (
                                oneContract.contractAmount -
                                allMoney +
                                (Number(onePLan.returnAmount) || 0)
                            ).toFixed(2)
                        }}
                    </div>
                </el-form-item>
                <el-form-item label="回款比例">
                    <div style="width: 240px">
                        {{
                            form.returnAmount
                                ? (
                                      (form.returnAmount /
                                          oneContract.contractAmount) *
                                      100
                                  ).toFixed(2) + '%'
                                : '- -'
                        }}
                    </div>
                </el-form-item>
                <el-form-item label="回款年月" required>
                    <el-date-picker
                        style="width: 240px"
                        v-model="form.returnTime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="month"
                        placeholder="选择年月"
                        :picker-options="expireTimeOption"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="付款账户">
                    <el-input
                        style="width: 240px"
                        placeholder="请输入"
                        v-model="form.contractA"
                    ></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input
                        type="textarea"
                        rows="3"
                        style="width: 240px"
                        placeholder="请输入"
                        v-model="form.remark"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    round
                    size="mini"
                    @click="onSubmit"
                    style="background: #2370eb"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <!-- 删除计划 -->
        <el-dialog
            :visible.sync="dialogDel"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="dilog_box"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    删除计划
                </div>
            </template>
            <div style="margin: 30px 0 100px 30px; font-size: 20px">
                是否删除回款计划？
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" round size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    round
                    size="mini"
                    @click="onSubmitDel"
                    style="background: #2370eb"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    addPlan,
    planList,
    updatePlan,
    delPlan,
    getWater as waterList,
    planArriveList,
} from '@/api/contr/money.js';
import Plan from './plan/plan.vue';
import Info from './info/info.vue';
import { arriveList } from '@/api/arrive/arrive.js';
export default {
    components: {
        Plan,
        Info,
    },
    data() {
        return {
            index: 1,
            planFold: true,
            infoFold: true,
            dialog: false,
            dialogDel: false,
            form: {
                contractA: '',
            },
            isEidt: false,
            onePLan: {},
            allMoney: 0,
            allOnMoney2: 0,
            oneContract: {},
            btnP: {},
            matchingData: [],
            expireTimeOption: {
                disabledDate(date) {
                    // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
                    return date.getTime() < Date.now() - 8.64e7;
                },
            },
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll, true);
    },
    methods: {
        getData(data, allMoney, oneContract, btnP) {
            this.btnP = btnP;
            this.oneContract = oneContract;
            this.onePLan = data.length > 0 ? data[0] : {};
            this.allMoney = allMoney;
            for (let i = 0; i < data.length; i++) {
                if (data[i].paymentWay == 3) {
                    let obj = {
                        param: {
                            contractPlanId: data[i].id,
                        },
                    };
                    arriveList(obj).then((res) => {
                        if (res.data.list.length > 0) {
                            data[i].tradeNo = res.data.list[0].tradeNo;
                            data[i].externalUserName =
                                res.data.list[0].externalUserName;
                            data[i].createTime = res.data.list[0].createTime;
                        }
                    });
                }
            }
            setTimeout(() => {
                this.$refs.plan.getData(data, allMoney, oneContract, btnP);
            }, 200);

            this.waterList(oneContract.id);
            console.log(oneContract);
            let obj = {
                param: {
                    contractId: oneContract.id,
                },
                pageNum: 0,
                pageSize: 0,
            };
            arriveList(obj).then((res) => {
                console.log(obj);
                this.matchingData = res.data.list;
            });
        },
        allOnMoney(data) {
            this.allOnMoney2 = data;
        },
        onAdd() {
            this.form = {};
            this.isEidt = false;
            this.dialog = true;
            this.onePLan = {};
            this.form = JSON.parse(JSON.stringify(this.oneContract));
        },
        edit(row) {
            this.isEidt = true;
            this.onePLan = row;
            this.dialog = true;
            this.form = JSON.parse(JSON.stringify(row));
        },
        onSwitch(i, id) {
            this.index = i;
            this.$el
                .querySelector('.' + id)
                .scrollIntoView({ block: 'start', behavior: 'smooth' });
        },
        waterList(contractId) {
            let data = {
                param: {
                    contractId,
                },
                pageNum: 0,
                pageSize: 0,
            };
            waterList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.$refs.info.getWaterList(res.data.list, this.btnP);
                }
            });
        },
        onSubmitDel() {
            let data = {
                param: {
                    id: this.onePLan.id,
                },
            };
            delPlan(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleClose();
                }
            });
        },
        // 申请发票
        onApply() {
            this.$router.push({
                path: '/addApplyinvoice',
                query: {
                    contracId: this.oneContract.id,
                },
            });
        },
        del(row) {
            this.onePLan = row;
            this.dialogDel = true;
        },
        onSubmit() {
            if (!this.form.returnAmount) {
                return this.$message.error('请输入回款金额');
            }
            if (Number(this.form.returnAmount) === 0) {
                return this.$message.error('请输入回款金额');
            }
            if (!this.form.returnTime) {
                return this.$message.error('请选择回款年月');
            }
            if (this.isEidt) {
                if (
                    this.allMoney +
                        Number(this.form.returnAmount) -
                        this.onePLan.returnAmount >
                    this.oneContract.contractAmount
                ) {
                    return this.$message.error(
                        '回款金额应小于剩余可计划回款金额'
                    );
                }
                let data = {
                    param: {
                        id: this.onePLan.id,
                        contractA: this.form.contractA
                            ? this.form.contractA
                            : this.onePLan.contractA,
                        contractB: this.onePLan.contractB,
                        returnAmount: this.form.returnAmount,
                        returnTime: this.form.returnTime,
                        remark: this.form.remark,
                    },
                };
                updatePlan(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.handleClose();
                    }
                });
            } else {
                if (
                    this.allMoney + Number(this.form.returnAmount) >
                    this.oneContract.contractAmount
                ) {
                    return this.$message.error(
                        '回款金额应小于剩余可计划回款金额'
                    );
                }
                let data = {
                    param: {
                        contractId: this.oneContract.id,
                        contractA: this.form.contractA
                            ? this.form.contractA
                            : this.oneContract.contractA,
                        contractB: this.oneContract.contractB,
                        returnAmount: this.form.returnAmount,
                        returnTime: this.form.returnTime,
                        remark: this.form.remark,
                    },
                };
                addPlan(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.handleClose();
                    }
                });
            }
        },
        handleClose() {
            this.dialog = false;
            this.dialogDel = false;
            // console.log(this.oneContract.id);
            this.$emit('planList', this.oneContract.id);
            this.form = {};
        },
        getMoney() {
            this.$emit('getMoney');
        },
    },
    beforeDestroy: function () {
        window.removeEventListener('scroll', this.handleScroll);
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.money {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 0px;
    .switch {
        background: #fff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 13px;
        color: #333333;
        padding: 12px 13px 12px 0;
        box-shadow: 0px 12px 8px -12px #ccc;
        z-index: 111;
        .shu {
            width: 2px;
            height: 16px;
            background: #2370eb;
            display: inline-block;
            margin-right: 22px;
        }
        .tab {
            span {
                width: 72px;
                height: 20px;
                border-radius: 2px;
                background: rgba(35, 112, 235, 0.16);
                font-size: 12px;
                line-height: 20px;
                color: #333;
                margin-left: 8px;
                text-align: center;
                display: inline-block;
                cursor: pointer;
                border-radius: 10px;
            }
            .isColor {
                background-color: #2370eb;
                color: #fff;
            }
        }
    }
    .content {
        flex: 1;
        overflow: auto;
        padding-bottom: 20px;
        .title {
            height: 49px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #eeeeee;
            font-size: 13px;
            background: #fff;
        }
        .Plan_box {
            margin-top: 12px;
            background: #fff;
        }
        .Info_box {
            margin-top: 12px;
            background: #fff;
        }
    }
}
.dilog_box {
    .el-form {
        width: 80%;
        margin: 20px auto 80px;
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
</style>
