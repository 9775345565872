import http from '@/utils/http.js'

// 添加回款计划
export function addPlan(data) {
     return http({
          url: "/jasoboss/contract/return/plan/add",
          method: "post",
          data
     })
}
// 回款计划修改
export function updatePlan(data) {
     return http({
          url: "/jasoboss/contract/return/plan/update",
          method: "post",
          data
     })
}
// 获取单个回款
export function getOnePlan(data) {
     return http({
          url: "/jasoboss/contract/return/plan/get",
          method: "post",
          data
     })
}
// 回款计划列表
export function planList(data) {
     return http({
          url: "/jasoboss/contract/return/plan/list",
          method: "post",
          data
     })
}
// 回款计划删除
export function delPlan(data) {
     return http({
          url: "/jasoboss/contract/return/plan/delete",
          method: "post",
          data
     })
}
// 回款流水列表
export function getWater(data) {
     return http({
          url: "/jasoboss/contract/return/water/list",
          method: "post",
          data
     })
}
// 申请发票
export function addInvoice(data) {
     return http({
          url: "/jasoboss/contract/invoice/add",
          method: "post",
          data
     })
}
// 获取单个合同
export function getOneContract(data) {
     return http({
          url: "/jasoboss/contract/get",
          method: "post",
          data
     })
}
// 查询未核销的列表
export function planArriveList(data) {
     return http({
          url: "/jasoboss/contract/return/plan/arrive/list",
          method: "post",
          data
     })
}
// 回款列表数据统计
export function planBrief(data) {
     return http({
          url: "/jasoboss/contract/return/plan/table/brief",
          method: "post",
          data
     })
}
// 回款流水列表数据统计
export function customerBrief(data) {
     return http({
          url: "/jasoboss/contract/return/plan/customer/table/brief",
          method: "post",
          data
     })
}
// 回款流水列表数据统计
export function waterBrief(data) {
     return http({
          url: "/jasoboss/contract/return/water/table/brief",
          method: "post",
          data
     })
}