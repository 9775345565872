<template>
    <div class="order_plan">
        <div class="list_box">
            <div v-for="(item, index) in data" class="list_cont">
                <p class="list_ind">
                    <span>计划{{ index + 1 }}</span>
                    <span :class="item.status == 1 ? 'off' : 'on'"
                        ><i
                            :class="
                                item.status == 1
                                    ? 'el-icon-error'
                                    : 'el-icon-success'
                            "
                        ></i
                        >{{ item.status == 1 ? '未交付' : '已交付' }}</span
                    >
                    <el-button
                        style="
                            float: right;
                            margin-right: 10px;
                            color: red;
                            margin-top: 5px;
                        "
                        type="text"
                        v-if="item.status == 1 && btnP.delDeliver"
                        @click="del(item, index)"
                    >
                        删除
                    </el-button>
                    <el-button
                        style="
                            float: right;
                            margin-right: 10px;
                            color: #333;
                            margin-top: 5px;
                        "
                        type="text"
                        v-if="item.status == 1 && btnP.updateDeliver"
                        @click="edit(item, index)"
                    >
                        <i
                            class="el-icon-edit-outline"
                            style="color: #2370eb"
                        ></i>
                        编辑
                    </el-button>
                </p>
                <el-form label-width="85px" label-position="left" size="small">
                    <div class="form_box">
                        <div class="column">
                            <el-form-item label="交付时间">
                                <div
                                    style="
                                        width: 200px;
                                        margin-right: 27px;
                                        padding-left: 15px;
                                    "
                                >
                                    {{
                                        item.deliverTime
                                            ? item.deliverTime.split(' ')[0]
                                            : '- -'
                                    }}
                                </div>
                            </el-form-item>

                            <el-form-item label="产品名称">
                                <div
                                    style="
                                        width: 200px;
                                        margin-right: 27px;
                                        padding-left: 15px;
                                    "
                                >
                                    {{
                                        $tableDataHandle.productCategory(
                                            item.productCategory
                                        )
                                    }}
                                </div>
                            </el-form-item>
                            <el-form-item label="用户数">
                                <div
                                    style="
                                        width: 200px;
                                        margin-right: 27px;
                                        padding-left: 15px;
                                    "
                                >
                                    <span
                                        v-if="
                                            item.productCategory == 1 ||
                                            item.productCategory == 2 ||
                                            item.productCategory == 4 ||
                                            item.productCategory == 10
                                        "
                                    >
                                        {{ item.orderNumber || 0 }} 用户
                                    </span>
                                    <span v-else> - - </span>
                                </div>
                            </el-form-item>
                            <el-form-item label="年限">
                                <div
                                    style="
                                        width: 200px;
                                        margin-right: 27px;
                                        padding-left: 15px;
                                    "
                                >
                                    <span
                                        v-if="
                                            item.productCategory == 1 ||
                                            item.productCategory == 2 ||
                                            item.productCategory == 4 ||
                                            item.productCategory == 3 ||
                                            item.productCategory == 12
                                        "
                                    >
                                        {{ item.term || 0 }} 年
                                    </span>
                                    <span v-else> - - </span>
                                </div>
                            </el-form-item>
                            <el-form-item label="创建时间">
                                <div
                                    style="
                                        width: 200px;
                                        margin-right: 27px;
                                        padding-left: 15px;
                                    "
                                >
                                    {{ item.createTime || '- -' }}
                                </div>
                            </el-form-item>
                            <el-form-item label="创建人">
                                <div
                                    style="
                                        width: 200px;
                                        margin-right: 27px;
                                        padding-left: 15px;
                                    "
                                >
                                    {{ item.adminName || '- -' }}
                                </div>
                            </el-form-item>
                            <el-form-item
                                label="域名"
                                v-if="
                                    item.status == 2 &&
                                    item.productCategory == 5
                                "
                            >
                                <div
                                    style="
                                        width: 200px;
                                        margin-right: 27px;
                                        padding-left: 15px;
                                    "
                                >
                                    {{ item.domain || '- -' }}
                                </div>
                            </el-form-item>
                            <el-form-item
                                label="CorpID"
                                v-if="
                                    item.status == 2 &&
                                    item.productCategory == 1
                                "
                            >
                                <div
                                    style="
                                        width: 200px;
                                        margin-right: 27px;
                                        padding-left: 15px;
                                    "
                                >
                                    {{ item.corpId || '- -' }}
                                </div>
                            </el-form-item>
                            <el-form-item
                                label="管理员账号"
                                v-if="
                                    item.status == 2 &&
                                    item.productCategory == 2
                                "
                            >
                                <div
                                    style="
                                        width: 200px;
                                        margin-right: 27px;
                                        padding-left: 15px;
                                    "
                                >
                                    {{ item.adminAccount || '- -' }}
                                </div>
                            </el-form-item>
                            <el-form-item
                                label="下单后台"
                                v-if="item.status == 2"
                            >
                                <div
                                    style="
                                        width: 200px;
                                        margin-right: 27px;
                                        padding-left: 15px;
                                    "
                                >
                                    {{
                                        item.placeBackstage === 0
                                            ? '伽搜boss'
                                            : item.placeBackstage === 1
                                            ? '钱邮'
                                            : item.placeBackstage === 2
                                            ? '腾讯云'
                                            : item.placeBackstage === 3
                                            ? '阿里云'
                                            : item.placeBackstage === 4
                                            ? '925'
                                            : item.placeBackstage === 5
                                            ? '华为云'
                                            : '- -'
                                    }}
                                </div>
                            </el-form-item>
                            <el-form-item
                                label="开通时间"
                                v-if="item.status == 2"
                            >
                                <div
                                    style="
                                        width: 200px;
                                        margin-right: 27px;
                                        padding-left: 15px;
                                    "
                                >
                                    {{
                                        item.openTime
                                            ? item.openTime.split(' ')[0]
                                            : '- -'
                                    }}
                                </div>
                            </el-form-item>
                            <el-form-item
                                label="到期时间"
                                v-if="item.status == 2"
                            >
                                <div
                                    style="
                                        width: 200px;
                                        margin-right: 27px;
                                        padding-left: 15px;
                                    "
                                >
                                    {{
                                        item.expireTime
                                            ? item.expireTime.split(' ')[0]
                                            : '- -'
                                    }}
                                </div>
                            </el-form-item>
                        </div>
                    </div>
                    <div
                        v-if="
                            item.orderPlanHistoryBOList &&
                            item.orderPlanHistoryBOList.length > 0
                        "
                    >
                        <div class="title">
                            <span style="margin-left: 20px"> 计划历史</span>
                            <p>
                                <el-button
                                    v-show="infoFold"
                                    @click="infoFold = !infoFold"
                                    type="text"
                                    style="margin-right: 12px; color: #2162cb"
                                >
                                    收起 <i class="el-icon-arrow-up"></i>
                                </el-button>
                                <el-button
                                    v-show="!infoFold"
                                    @click="infoFold = !infoFold"
                                    type="text"
                                    style="margin-right: 12px; color: #2162cb"
                                >
                                    展开 <i class="el-icon-arrow-down"></i>
                                </el-button>
                            </p>
                        </div>
                        <el-table
                            v-show="infoFold"
                            class="table-box"
                            ref="multipleTable"
                            :data="item.orderPlanHistoryBOList"
                            height="100%"
                            :header-cell-style="{
                                background: '#FAFAFA',
                                color: '#333333',
                                'font-size': '14px',
                                'font-weight': '500',
                            }"
                        >
                            <el-table-column prop="" width="10">
                            </el-table-column>
                            <el-table-column
                                width="160"
                                prop="createTime"
                                label="操作时间"
                                show-overflow-tooltip
                            >
                            </el-table-column>

                            <el-table-column
                                width="150"
                                prop="adminName"
                                label="操作人员"
                                show-overflow-tooltip
                            >
                            </el-table-column>
                            <el-table-column
                                prop="remark"
                                label="操作日志"
                                show-overflow-tooltip
                            >
                            </el-table-column>

                            <div slot="empty" class="empty">
                                <img src="@/assets/zanwushuju.png" />
                                <span style="display: block">暂无数据～</span>
                            </div>
                        </el-table>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import { orderPlanList } from '@/api/order/order.js';
export default {
    data() {
        return {
            data: [],
            isUpData: true,
            infoFold: true,
            originalData: {},
            btnP: {},
        };
    },
    methods: {
        getData(row, btnP) {
            this.btnP = btnP;
            let data = {
                param: {
                    orderId: row,
                },
            };
            orderPlanList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.data = res.data.list;
                }
            });
            // this.originalData = data;
            // row.orderPlanBOList.forEach((item) => {
            //   item.productCategory = this.originalData.productCategory;
            //   item.adminName = this.originalData.adminName;
            // });
            // this.row = data.orderPlanBOList;
        },
        edit(row) {
            // row.orderNo = this.originalData.orderNo;
            // row.companyName = this.originalData.companyName;
            // row.receivableAmount = this.originalData.receivableAmount;
            // row.productCategory = this.originalData.productCategory;

            this.$emit('edit', row);
        },
        del(row) {
            this.$emit('del', row);
        },
    },
};
</script>
<style lang="scss" scoped>
.order_plan {
    padding-top: 16px;
    .list_box {
        margin: 0px 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .list_ind {
            line-height: 49px;
            font-size: 13px;
            color: #333333;
            padding-left: 20px;
            border-bottom: 1px dashed #e5e5e5;
            .on {
                display: inline-block;
                width: 88px;
                margin-left: 20px;
                height: 25px;
                background: #e0eaff;
                text-align: center;
                border-radius: 13px;
                line-height: 25px;
                font-size: 13px;
                i {
                    color: #82a7fc;
                    margin-right: 5px;
                }
            }
            .bufen {
                display: inline-block;
                width: 88px;
                margin-left: 20px;
                height: 25px;
                background: #fce1b3;
                text-align: center;
                border-radius: 13px;
                line-height: 25px;
                font-size: 13px;
                i {
                    color: #f5a623;
                    margin-right: 5px;
                }
            }
            .off {
                display: inline-block;
                width: 88px;
                margin-left: 20px;
                text-align: center;
                height: 25px;
                background: #ece2fe;
                border-radius: 13px;
                line-height: 25px;
                font-size: 13px;
                i {
                    margin-right: 5px;
                    color: #7f3af5;
                }
            }
        }
        .el-form {
            /deep/ .el-form-item {
                margin-bottom: 0;
                width: 300px;
            }
        }
        .list_cont {
            width: 100%;
            border-radius: 8px;
            border: 1px solid #e5e5e5;
            margin-bottom: 10px;

            .form_box {
                display: flex;
                .column {
                    padding-top: 12px;
                    margin-left: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    padding-bottom: 10px;
                }
                .columns {
                    padding-top: 12px;
                    flex: 1.5;
                }
            }
            .title {
                height: 30px;
                margin-top: 10px;
                margin-bottom: 10px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #eeeeee;
                font-size: 13px;
                background: #fff;
            }
        }
    }
}
.el-input--small {
    font-size: 14px !important;
}
</style>
<style>
.order_plan .el-input.is-disabled .el-input__inner {
    background: #fff !important;
}
.order_plan .el-input.is-disabled .el-input__inner::placeholder {
    display: none !important;
    color: #fff !important;
}
.order_plan .el-form-item__label {
    color: #666666 !important;
}
</style>
