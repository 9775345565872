<template>
    <div class="order_info">
        <div class="top_title">
            <p>
                <span>明细</span>
                {{ contrart.remark }}
            </p>
        </div>
        <div class="list_box">
            <div v-for="(item, index) in data" class="list_cont">
                <p class="list_ind">
                    <span>产品{{ index + 1 }}</span>
                    <span
                        :class="
                            item.status == 1
                                ? 'off'
                                : item.status == 2
                                ? 'bufen'
                                : 'on'
                        "
                        ><i
                            :class="
                                item.status == 1
                                    ? 'el-icon-error'
                                    : item.status == 2
                                    ? 'iconfont icon-biaozhunfenbufenxiangku'
                                    : 'el-icon-success'
                            "
                        ></i
                        >{{
                            item.status == 1
                                ? '未交付'
                                : item.status == 2
                                ? '部分交付'
                                : '已交付'
                        }}</span
                    >
                    <!-- <el-button
                        v-if="num(item) && item.status != 3 && btnP.addDeliver"
                        style="background: #fff;
                border-radius: 2px;
                padding: 0 7px 0 12px;
                height:24px;
                color:#2370EB;
                border-color: #2370EB;
                float:right;margin-top:12px;margin-right:10px"
                        @click="add(item)"
                    >
                        + 新增计划
                    </el-button>
                    <el-button
                        v-if="item.orderPlanBOList && item.orderPlanBOList.length > 0"
                        style="background: #fff;
                border-radius: 2px;
                padding: 0 7px 0 12px;
                height:24px;
                color:#2370EB;
                border-color: #2370EB;
                float:right;margin-top:12px;margin-right:10px"
                        @click="detail(item)"
                    >
                        交付详情
                    </el-button> -->
                </p>
                <el-form label-width="70px" label-position="left" size="small">
                    <div class="form_box">
                        <div class="column">
                            <el-form-item
                                label="订单编号"
                                :required="!isUpData"
                            >
                                <el-input
                                    :disabled="isUpData"
                                    v-model="item.orderNo"
                                    placeholder="请输入"
                                    style="width: 230px; margin-right: 27px"
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                label="创建时间"
                                :required="!isUpData"
                            >
                                <el-input
                                    :disabled="isUpData"
                                    v-model="item.createTime"
                                    placeholder="请输入"
                                    style="width: 230px; margin-right: 27px"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="产品名称">
                                <div
                                    style="
                                        width: 230px;
                                        margin-right: 27px;
                                        padding-left: 15px;
                                    "
                                >
                                    {{
                                        item.productAttributeRelationBOList &&
                                        item.productAttributeRelationBOList
                                            .length > 0
                                            ? item
                                                  .productAttributeRelationBOList[0]
                                                  .productName
                                            : '- -'
                                    }}
                                </div>
                            </el-form-item>

                            <el-form-item label="购买类型">
                                <div
                                    style="
                                        width: 230px;
                                        margin-right: 27px;
                                        padding-left: 15px;
                                    "
                                >
                                    {{ returnOrderType(item) }}
                                </div>
                            </el-form-item>
                            <el-form-item label="用户数">
                                <div
                                    style="
                                        width: 230px;
                                        margin-right: 27px;
                                        padding-left: 15px;
                                    "
                                >
                                    <span>
                                        {{ item.orderNumber || '0' }} 用户
                                    </span>
                                </div>
                            </el-form-item>
                            <el-form-item label="年限">
                                <div
                                    style="
                                        width: 230px;
                                        margin-right: 27px;
                                        padding-left: 15px;
                                    "
                                >
                                    <span>
                                        {{ item.orderTerm || '0' }} 年
                                    </span>
                                </div>
                            </el-form-item>

                            <el-form-item label="结算价" v-if="btnP.depSearch">
                                <div
                                    style="
                                        width: 230px;
                                        margin-right: 27px;
                                        padding-left: 15px;
                                    "
                                >
                                    {{ item.settleAmount + ' 元' || '- -' }}
                                </div>
                            </el-form-item>
                            <el-form-item label="指导价">
                                <div
                                    style="
                                        width: 230px;
                                        margin-right: 27px;
                                        padding-left: 15px;
                                    "
                                >
                                    {{ item.guideAmount + ' 元' || '- -' }}
                                </div>
                            </el-form-item>

                            <el-form-item label="应收金额">
                                <div
                                    style="
                                        width: 230px;
                                        margin-right: 27px;
                                        padding-left: 15px;
                                    "
                                >
                                    {{ item.receivableAmount + ' 元' || '- -' }}
                                </div>
                            </el-form-item>
                            <el-form-item label="回款时间">
                                <div
                                    style="
                                        width: 230px;
                                        margin-right: 27px;
                                        padding-left: 15px;
                                    "
                                >
                                    {{
                                        item.contractReturnWaterBOList.length >
                                        0
                                            ? item.contractReturnWaterBOList[0].receivedTime.split(
                                                  ' '
                                              )[0]
                                            : ' - -'
                                    }}
                                </div>
                            </el-form-item>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
        <!-- 新增 -->
        <el-drawer
            :visible.sync="drawerAdd"
            :direction="direction"
            :before-close="handleDrawerClose"
            size="500px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    新增交付计划
                </div>
            </template>
            <Add ref="add" @close="handleDrawerClose"></Add>
        </el-drawer>
    </div>
</template>
<script>
import Add from '../../../../order/orderList/add/add.vue';
import { closedCusList } from '@/api/closedCus/closedCus.js';
export default {
    data() {
        return {
            data: [],
            isUpData: true,
            drawerAdd: false,
            direction: 'rtl',
            contrart: {},
            btnP: {},
        };
    },
    components: {
        Add,
    },
    methods: {
        getData(data, contrart, btn) {
            // console.log(data);
            this.btnP = btn;
            this.data = data;
            this.contrart = contrart;
            data.forEach((item, index) => {
                if (
                    item.orderPlanBOList.length > 0 &&
                    item.orderPlanBOList[0].openTime
                ) {
                    this.closedCusList(
                        item.orderPlanBOList[0].customerId,
                        index
                    );
                }
            });
        },
        closedCusList(customerId, index) {
            let data = {
                param: {
                    customerId,
                },
            };
            closedCusList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    if (res.data.list.length > 0) {
                        this.data[index].orderPlanBOList[0].customerData =
                            res.data.list[0];
                    }
                }
            });
        },

        detail(data) {
            this.$emit('deliverDetail', data.id);
        },

        handleDrawerClose(data) {
            this.drawerAdd = false;
            this.$emit('orderList', this.data[0].contractId);
        },
        returnOrderType(data) {
            let a = '- -';
            data.productAttributeRelationBOList.forEach((item) => {
                if (item.attributeColumnName == 'productType') {
                    item.productAttributeValueBOList &&
                    item.productAttributeValueBOList.length > 0
                        ? item.productAttributeValueBOList.forEach((itm) => {
                              if (data.orderType == itm.attributeValue) {
                                  a = itm.attributeValueName;
                              }
                          })
                        : '- -';
                }
            });
            return a;
        },
        num(item) {
            if (
                item.productCategory == 1 ||
                item.productCategory == 2 ||
                item.productCategory == 3 ||
                item.productCategory == 4
            ) {
                var num = 0;
                item.orderPlanBOList.forEach((itm) => {
                    num += Number(itm.term);
                });
                return num - item.orderTerm >= 0 ? false : true;
            } else {
                if (item.orderPlanBOList.length > 0) {
                    return false;
                }
                return true;
            }
        },
        add(row) {
            this.drawerAdd = true;
            setTimeout(() => {
                this.$refs.add.getData(row);
            }, 0);
        },
    },
};
</script>
<style lang="scss" scoped>
.order_info {
    .top_title {
        margin: 10px 20px;
        background: #f0f4ff;
        border-radius: 7px;
        p {
            margin-left: 20px;
            height: 50px;
            line-height: 50px;
        }
        p:nth-child(1) {
            span:nth-child(odd) {
                font-size: 13px;
                color: #333333;
                margin-right: 20px;
            }
            span:nth-child(even) {
                font-size: 13px;
                color: #333333;
                font-weight: 600;
                margin-right: 40px;
            }
        }
    }

    .list_box {
        margin: 0px 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .list_ind {
            line-height: 49px;
            font-size: 13px;
            color: #333333;
            padding-left: 20px;
            border-bottom: 1px dashed #e5e5e5;
            .on {
                display: inline-block;
                width: 88px;
                margin-left: 20px;
                height: 25px;
                background: #e0eaff;
                text-align: center;
                border-radius: 13px;
                line-height: 25px;
                font-size: 13px;
                i {
                    color: #82a7fc;
                    margin-right: 5px;
                }
            }
            .bufen {
                display: inline-block;
                width: 88px;
                margin-left: 20px;
                height: 25px;
                background: #fce1b3;
                text-align: center;
                border-radius: 13px;
                line-height: 25px;
                font-size: 13px;
                i {
                    color: #f5a623;
                    margin-right: 5px;
                }
            }
            .off {
                display: inline-block;
                width: 88px;
                margin-left: 20px;
                text-align: center;
                height: 25px;
                background: #ece2fe;
                border-radius: 13px;
                line-height: 25px;
                font-size: 13px;
                i {
                    margin-right: 5px;
                    color: #7f3af5;
                }
            }
        }
        .el-form {
            display: flex;
            flex-wrap: wrap;
            /deep/ .el-form-item {
                width: 300px;
                margin-bottom: 5px;
            }
        }
        .list_cont {
            width: 100%;
            border-radius: 8px;
            border: 1px solid #e5e5e5;
            margin-bottom: 10px;
            padding-bottom: 8px;
            .form_box {
                display: flex;
                .column {
                    padding-top: 12px;
                    margin-left: 20px;
                    display: flex;
                    flex-wrap: wrap;
                }
                .columns {
                    padding-top: 12px;
                    flex: 1.5;
                }
            }
        }
        // .list_cont:nth-child(odd) {
        //   margin-right: 0.5%;
        // }
    }
}
.el-input--small {
    font-size: 14px !important;
}
</style>
<style>
.order_info .el-input.is-disabled .el-input__inner {
    background: #fff !important;
}
.order_info .el-input.is-disabled .el-input__inner::placeholder {
    display: none !important;
    color: #fff !important;
}
.order_info .el-form-item__label {
    color: #666666 !important;
}
</style>
