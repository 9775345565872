<template>
    <div class="order">
        <div class="switch">
            <p>
                <span class="shu"></span>
                订单
            </p>
            <div class="tab">
                <span :class="index == 1 ? 'isColor' : ''" @click="onSwitch(1, 'Info_box')"
                    >订单信息</span
                >
                <span :class="index == 2 ? 'isColor' : ''" @click="onSwitch(2, 'Plan_box')"
                    >交付计划</span
                >
            </div>
        </div>
        <div class="content" ref="content">
            <div class="Info_box">
                <div class="title">
                    <span style="margin-left:6px">
                        <i class="iconfont icon-ziliao" style="color:#2370EB"></i>
                        订单信息</span
                    >
                    <p>
                        <el-button
                            v-show="infoFold"
                            @click="infoFold = !infoFold"
                            type="text"
                            style="margin-right:12px;color:#2162CB"
                        >
                            收起 <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button
                            v-show="!infoFold"
                            @click="infoFold = !infoFold"
                            type="text"
                            style="margin-right:12px;color:#2162CB"
                        >
                            展开 <i class="el-icon-arrow-down"></i>
                        </el-button>
                    </p>
                </div>
                <div>
                    <el-collapse-transition>
                        <Info
                            v-show="infoFold"
                            @deliverDetail="deliverDetail"
                            ref="info"
                            @orderList="orderList"
                        />
                    </el-collapse-transition>
                </div>
            </div>
            <div class="Plan_box" v-show="showPlan">
                <div class="title">
                    <span style="margin-left:6px">
                        <i
                            class="iconfont icon-lianxi2hebing_jilu"
                            style="color:#2370EB;font-size:17px"
                        ></i>
                        交付计划</span
                    >
                    <p>
                        <el-button
                            style="margin-right:10px;color:#333;margin-top:5px"
                            type="text"
                            @click="close"
                        >
                            <i class="el-icon-error" style="color:#2370EB"></i>
                            关闭
                        </el-button>
                    </p>
                </div>
                <div>
                    <el-collapse-transition>
                        <Plan ref="plan" @del="del" @edit="edit" />
                    </el-collapse-transition>
                </div>
            </div>
        </div>
        <!-- 删除计划 -->
        <el-dialog
            :visible.sync="dialogDel"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="dilog_box"
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
                >
                    删除计划
                </div>
            </template>
            <div style="margin:30px 0 100px 30px;font-size:20px">
                是否删除交付计划？
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button @click="handleClose" round size="mini">取 消</el-button>
                <el-button
                    type="primary"
                    round
                    size="mini"
                    @click="onSubmitDel"
                    style="background:#2370eb"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
        <!-- 编辑交付计划 -->
        <el-drawer
            :visible.sync="drawerUpdate"
            :direction="direction"
            :before-close="handleDrawerClose"
            size="500px"
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;font-size:16px;font-weight: 500;color: rgba(0, 0, 0, 0.85);line-height: 24px;"
                >
                    编辑交付计划
                </div>
            </template>
            <Edit ref="edit" @close="handleDrawerClose"></Edit>
        </el-drawer>
    </div>
</template>
<script>
import { delOrderPlan } from '@/api/order/order.js';
import Info from './info/info.vue';
import Plan from './plan/plan.vue';
import Edit from '../../../order/orderDeliver/edit/edit.vue';
export default {
    components: {
        Info,
        Plan,
        Edit,
    },
    data() {
        return {
            index: 1,
            infoFold: true,
            dialogDel: false,
            order: {},
            direction: 'rtl',
            drawerUpdate: false,
            btnP: {},
            showPlan: false,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll, true);
    },
    methods: {
        handleClose() {
            this.dialogDel = false;
        },
        del(row) {
            this.order = row;
            this.dialogDel = true;
        },
        edit(row) {
            // console.log(row);
            this.order = row;
            this.drawerUpdate = true;
            setTimeout(() => {
                this.$refs.edit.getData(row);
            }, 0);
        },
        onSubmitDel() {
            let data = {
                param: {
                    id: this.order.id,
                },
            };
            delOrderPlan(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.orderList(this.order.contractId);
                    this.handleClose();
                    let index = this.$refs.plan.$data.data.findIndex(
                        (item) => item.id == this.order.id,
                    );
                    this.$refs.plan.$data.data.splice(index, 1);
                }
            });
        },
        handleDrawerClose(disabled) {
            this.drawerUpdate = false;
            if (disabled) {
                var index = this.$refs.plan.$data.data.findIndex((item) => {
                    return item.id == this.order.id;
                });
                this.orderList(this.order.contractId);
                this.$refs.plan.$data.data[index].term = this.$refs.edit.$data.form.term;
                if (
                    this.order.productCategory == 1 ||
                    this.order.productCategory == 2 ||
                    this.order.productCategory == 4
                ) {
                    this.$refs.plan.$data.data[
                        index
                    ].deliverTime = this.$refs.edit.$data.form.deliverTime;
                }
            }
        },
        getData(data, contract, btnP) {
            this.btnP = btnP;
            this.$refs.info.getData(data, contract, btnP);
        },
        deliverDetail(data) {
            let currScrollHeight = this.$refs.content.scrollHeight;
            this.$refs.content.scrollTo(0, currScrollHeight);
            this.$refs.plan.getData(data, this.btnP);
            this.showPlan = true;
        },
        orderList(data) {
            this.$emit('orderList', data);
        },
        onSwitch(i, id) {
            this.index = i;
            this.$el.querySelector('.' + id).scrollIntoView({ block: 'start', behavior: 'smooth' });
        },
        close() {
            this.showPlan = false;
        },
    },
    beforeDestroy: function() {
        window.removeEventListener('scroll', this.handleScroll);
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.order {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 0px;
    .switch {
        background: #fff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 13px;
        color: #333333;
        padding: 12px 13px 12px 0;
        box-shadow: 0px 12px 8px -12px #ccc;
        z-index: 111;
        .shu {
            width: 2px;
            height: 16px;
            background: #2370eb;
            display: inline-block;
            margin-right: 22px;
        }
        .tab {
            span {
                width: 72px;
                height: 20px;
                border-radius: 2px;
                background: rgba(35, 112, 235, 0.16);
                font-size: 12px;
                line-height: 20px;
                color: #333;
                margin-left: 8px;
                text-align: center;
                display: inline-block;
                cursor: pointer;
                border-radius: 10px;
            }
            .isColor {
                background-color: #2370eb;
                color: #fff;
            }
        }
    }
    .content {
        flex: 1;
        overflow: auto;
        padding-bottom: 20px;
        .title {
            height: 49px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #eeeeee;
            font-size: 13px;
            background: #fff;
        }
        .Plan_box {
            margin-top: 12px;
            background: #fff;
        }
        .Info_box {
            margin-top: 12px;
            background: #fff;
        }
    }
}
</style>
