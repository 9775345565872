var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order_plan"},[_c('div',{staticClass:"list_box"},_vm._l((_vm.data),function(item,index){return _c('div',{staticClass:"list_cont"},[_c('p',{staticClass:"list_ind"},[_c('span',[_vm._v("计划"+_vm._s(index + 1))]),_c('span',{class:item.status == 1 ? 'off' : 'on'},[_c('i',{class:item.status == 1
                                ? 'el-icon-error'
                                : 'el-icon-success'}),_vm._v(_vm._s(item.status == 1 ? '未交付' : '已交付'))]),(item.status == 1 && _vm.btnP.delDeliver)?_c('el-button',{staticStyle:{"float":"right","margin-right":"10px","color":"red","margin-top":"5px"},attrs:{"type":"text"},on:{"click":function($event){return _vm.del(item, index)}}},[_vm._v(" 删除 ")]):_vm._e(),(item.status == 1 && _vm.btnP.updateDeliver)?_c('el-button',{staticStyle:{"float":"right","margin-right":"10px","color":"#333","margin-top":"5px"},attrs:{"type":"text"},on:{"click":function($event){return _vm.edit(item, index)}}},[_c('i',{staticClass:"el-icon-edit-outline",staticStyle:{"color":"#2370eb"}}),_vm._v(" 编辑 ")]):_vm._e()],1),_c('el-form',{attrs:{"label-width":"85px","label-position":"left","size":"small"}},[_c('div',{staticClass:"form_box"},[_c('div',{staticClass:"column"},[_c('el-form-item',{attrs:{"label":"交付时间"}},[_c('div',{staticStyle:{"width":"200px","margin-right":"27px","padding-left":"15px"}},[_vm._v(" "+_vm._s(item.deliverTime ? item.deliverTime.split(' ')[0] : '- -')+" ")])]),_c('el-form-item',{attrs:{"label":"产品名称"}},[_c('div',{staticStyle:{"width":"200px","margin-right":"27px","padding-left":"15px"}},[_vm._v(" "+_vm._s(_vm.$tableDataHandle.productCategory( item.productCategory ))+" ")])]),_c('el-form-item',{attrs:{"label":"用户数"}},[_c('div',{staticStyle:{"width":"200px","margin-right":"27px","padding-left":"15px"}},[(
                                        item.productCategory == 1 ||
                                        item.productCategory == 2 ||
                                        item.productCategory == 4 ||
                                        item.productCategory == 10
                                    )?_c('span',[_vm._v(" "+_vm._s(item.orderNumber || 0)+" 用户 ")]):_c('span',[_vm._v(" - - ")])])]),_c('el-form-item',{attrs:{"label":"年限"}},[_c('div',{staticStyle:{"width":"200px","margin-right":"27px","padding-left":"15px"}},[(
                                        item.productCategory == 1 ||
                                        item.productCategory == 2 ||
                                        item.productCategory == 4 ||
                                        item.productCategory == 3 ||
                                        item.productCategory == 12
                                    )?_c('span',[_vm._v(" "+_vm._s(item.term || 0)+" 年 ")]):_c('span',[_vm._v(" - - ")])])]),_c('el-form-item',{attrs:{"label":"创建时间"}},[_c('div',{staticStyle:{"width":"200px","margin-right":"27px","padding-left":"15px"}},[_vm._v(" "+_vm._s(item.createTime || '- -')+" ")])]),_c('el-form-item',{attrs:{"label":"创建人"}},[_c('div',{staticStyle:{"width":"200px","margin-right":"27px","padding-left":"15px"}},[_vm._v(" "+_vm._s(item.adminName || '- -')+" ")])]),(
                                item.status == 2 &&
                                item.productCategory == 5
                            )?_c('el-form-item',{attrs:{"label":"域名"}},[_c('div',{staticStyle:{"width":"200px","margin-right":"27px","padding-left":"15px"}},[_vm._v(" "+_vm._s(item.domain || '- -')+" ")])]):_vm._e(),(
                                item.status == 2 &&
                                item.productCategory == 1
                            )?_c('el-form-item',{attrs:{"label":"CorpID"}},[_c('div',{staticStyle:{"width":"200px","margin-right":"27px","padding-left":"15px"}},[_vm._v(" "+_vm._s(item.corpId || '- -')+" ")])]):_vm._e(),(
                                item.status == 2 &&
                                item.productCategory == 2
                            )?_c('el-form-item',{attrs:{"label":"管理员账号"}},[_c('div',{staticStyle:{"width":"200px","margin-right":"27px","padding-left":"15px"}},[_vm._v(" "+_vm._s(item.adminAccount || '- -')+" ")])]):_vm._e(),(item.status == 2)?_c('el-form-item',{attrs:{"label":"下单后台"}},[_c('div',{staticStyle:{"width":"200px","margin-right":"27px","padding-left":"15px"}},[_vm._v(" "+_vm._s(item.placeBackstage === 0 ? '伽搜boss' : item.placeBackstage === 1 ? '钱邮' : item.placeBackstage === 2 ? '腾讯云' : item.placeBackstage === 3 ? '阿里云' : item.placeBackstage === 4 ? '925' : item.placeBackstage === 5 ? '华为云' : '- -')+" ")])]):_vm._e(),(item.status == 2)?_c('el-form-item',{attrs:{"label":"开通时间"}},[_c('div',{staticStyle:{"width":"200px","margin-right":"27px","padding-left":"15px"}},[_vm._v(" "+_vm._s(item.openTime ? item.openTime.split(' ')[0] : '- -')+" ")])]):_vm._e(),(item.status == 2)?_c('el-form-item',{attrs:{"label":"到期时间"}},[_c('div',{staticStyle:{"width":"200px","margin-right":"27px","padding-left":"15px"}},[_vm._v(" "+_vm._s(item.expireTime ? item.expireTime.split(' ')[0] : '- -')+" ")])]):_vm._e()],1)]),(
                        item.orderPlanHistoryBOList &&
                        item.orderPlanHistoryBOList.length > 0
                    )?_c('div',[_c('div',{staticClass:"title"},[_c('span',{staticStyle:{"margin-left":"20px"}},[_vm._v(" 计划历史")]),_c('p',[_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.infoFold),expression:"infoFold"}],staticStyle:{"margin-right":"12px","color":"#2162cb"},attrs:{"type":"text"},on:{"click":function($event){_vm.infoFold = !_vm.infoFold}}},[_vm._v(" 收起 "),_c('i',{staticClass:"el-icon-arrow-up"})]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.infoFold),expression:"!infoFold"}],staticStyle:{"margin-right":"12px","color":"#2162cb"},attrs:{"type":"text"},on:{"click":function($event){_vm.infoFold = !_vm.infoFold}}},[_vm._v(" 展开 "),_c('i',{staticClass:"el-icon-arrow-down"})])],1)]),_c('el-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.infoFold),expression:"infoFold"}],ref:"multipleTable",refInFor:true,staticClass:"table-box",attrs:{"data":item.orderPlanHistoryBOList,"height":"100%","header-cell-style":{
                            background: '#FAFAFA',
                            color: '#333333',
                            'font-size': '14px',
                            'font-weight': '500',
                        }}},[_c('el-table-column',{attrs:{"prop":"","width":"10"}}),_c('el-table-column',{attrs:{"width":"160","prop":"createTime","label":"操作时间","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"width":"150","prop":"adminName","label":"操作人员","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"remark","label":"操作日志","show-overflow-tooltip":""}}),_c('div',{staticClass:"empty",attrs:{"slot":"empty"},slot:"empty"},[_c('img',{attrs:{"src":require("@/assets/zanwushuju.png")}}),_c('span',{staticStyle:{"display":"block"}},[_vm._v("暂无数据～")])])],1)],1):_vm._e()])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }