<template>
    <div class="invoice">
        <div class="switch">
            <p>
                <span class="shu"></span>
                发票
            </p>
            <div class="tab">
                <span :class="index == 1 ? 'isColor' : ''" @click="onSwitch(1, 'info_box')"
                    >开票信息</span
                >
            </div>
        </div>
        <div class="content">
            <div class="info_box">
                <div class="title">
                    <span style="margin-left:6px">
                        <i class="iconfont icon-Invoice" style="color:#2370EB"></i>
                        开票信息</span
                    >
                    <p>
                        <el-button
                            v-show="infoFold"
                            @click="infoFold = !infoFold"
                            type="text"
                            style="margin-right:12px;color:#2162CB"
                        >
                            收起 <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button
                            v-show="!infoFold"
                            @click="infoFold = !infoFold"
                            type="text"
                            style="margin-right:12px;color:#2162CB"
                        >
                            展开 <i class="el-icon-arrow-down"></i>
                        </el-button>
                    </p>
                </div>
                <div>
                    <el-collapse-transition>
                        <Info v-show="infoFold" ref="info" />
                    </el-collapse-transition>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Info from './info/info.vue';
export default {
    components: { Info },
    data() {
        return {
            index: 1,
            infoFold: true,
        };
    },
    methods: {
        onSwitch(i, id) {
            this.index = i;
            this.$el.querySelector('.' + id).scrollIntoView({ block: 'start', behavior: 'smooth' });
        },
        getData(data) {
            setTimeout(() => {
                this.$refs.info.getData(data);
            });
        },
    },
    beforeDestroy: function() {
        window.removeEventListener('scroll', this.handleScroll);
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.invoice {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 0px;
    .switch {
        background: #fff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 13px;
        color: #333333;
        padding: 12px 13px 12px 0;
        box-shadow: 0px 12px 8px -12px #ccc;
        z-index: 111;
        .shu {
            width: 2px;
            height: 16px;
            background: #2370eb;
            display: inline-block;
            margin-right: 22px;
        }
        .tab {
            span {
                width: 72px;
                height: 20px;
                border-radius: 2px;
                background: rgba(35, 112, 235, 0.16);
                font-size: 12px;
                line-height: 20px;
                color: #333;
                margin-left: 8px;
                text-align: center;
                display: inline-block;
                cursor: pointer;
                border-radius: 10px;
            }
            .isColor {
                background-color: #2370eb;
                color: #fff;
            }
        }
    }
    .content {
        flex: 1;
        overflow: auto;
        padding-bottom: 20px;
        display: flex;
        .title {
            height: 49px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #eeeeee;
            font-size: 13px;
            background: #fff;
        }
        .info_box {
            margin-top: 12px;
            background: #fff;
            flex: 1;
        }

        .history_box {
            margin-top: 12px;
            background: #fff;
        }
    }
}
</style>
