<template>
    <div class="custRepeat">
        <div class="content">
            <p class="title">
                <span style="margin-right: 16px">查询</span>
                <el-input
                    size="small"
                    style="width: 250px"
                    placeholder="回款编号、交易单号、付款方、金额"
                    v-model="tradeNoVal"
                    clearable
                >
                </el-input>
                <el-button
                    type="primary"
                    style="
                        margin-right: 16px;
                        border-color: #2370eb;
                        background: #2370eb;
                    "
                    size="small"
                    @click="getData(obj)"
                    icon="el-icon-search"
                ></el-button>
            </p>
            <el-table
                class="table-box"
                ref="multipleTable"
                :data="tableData"
                height="400px"
                :header-cell-style="{
                    background: '#FAFAFA',
                    color: '#333333',
                    'font-size': '14px',
                    'font-weight': '500',
                }"
            >
                <el-table-column width="80">
                    <template slot-scope="scope">
                        <el-radio
                            v-model="tableRadio"
                            :label="scope.row"
                            @change="Radio"
                            ><i></i
                        ></el-radio>
                    </template>
                </el-table-column>

                <el-table-column label="时间" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{
                            $tableDataHandle.createTime({
                                createTime: scope.row.receivedTime,
                            })
                        }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    label="编号"
                    prop="arriveNo"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.arriveNo || '- -' }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="付款方"
                    prop="contractA"
                    show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                    label="金额"
                    prop="amount"
                    show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                    label="备注"
                    prop="remark"
                    show-overflow-tooltip
                >
                </el-table-column>
                <div slot="empty" class="empty">
                    <img src="@/assets/zanwushuju.png" />
                    <span style="display: block">暂无数据～</span>
                </div>
            </el-table>
        </div>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background: none; border-color: #d5d6df; color: #666666"
                @click="onClose"
                >取消</el-button
            >

            <el-button
                round
                type="primary"
                size="mini"
                @click="onSubmit"
                :disabled="disabled"
                :style="disabled ? 'color:#dee0e3 ' : ''"
                >确认</el-button
            >
        </div>
    </div>
</template>
<script>
import {
    arriveMatchList,
    arriveMatch,
    arriveMatchSub,
} from '@/api/arrive/arrive.js';
import followUpVue from '../../../../components/details/followUp/followUp.vue';

export default {
    data() {
        return {
            tableData: [],
            tableRadio: '',
            money: '',
            tradeNoVal: '',
            disabled: true,
        };
    },
    methods: {
        getData(obj) {
            this.disabled = true;
            this.money = obj;
            this.tableRadio = '';
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                    // contractPlanId: 0,

                    // tradeState: 1,
                },
                pageNum: 0,
                pageSize: 0,
            };
            if (this.tradeNoVal) {
                data.param.name = this.tradeNoVal;
            }
            arriveMatchList(data).then((res) => {
                this.tableData = res.data.list;
            });
        },
        Radio() {
            if (!this.tableRadio) {
                this.disabled = true;
            } else {
                this.disabled = false;
            }
        },
        onSubmit() {
            console.log(this.tableRadio);
            if (!this.tableRadio) {
                return this.$message.error('请选择客户');
            }
            if (this.tableRadio.amount != this.money.returnAmount) {
                return this.$message.error('匹配金额不一致');
            }
            let data = {
                param: {
                    id: this.tableRadio.id,
                    contractPlanId: this.money.id,
                },
            };
            if (this.tableRadio.type == 1) {
                arriveMatch(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.$emit('close');
                    }
                });
            } else {
                arriveMatchSub(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.$emit('close');
                    }
                });
            }
        },
        onClose() {
            this.$emit('close');
            this.tradeNoVal = '';
            this.tableRadio = '';
        },
    },
};
</script>
<style lang="scss" scoped>
.custRepeat {
    display: flex;
    flex-direction: column;
    .content {
        padding: 0 20px;
        .title {
            padding: 16px 0;
            font-size: 14px;
            color: #333333;
            font-weight: 600;
        }
    }
    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        text-align: center;
        bottom: 0;
        button {
            width: 90px;
            z-index: 1000;
        }
    }
}
</style>
